import {
    getCustomers,
    generateCustomerId,
    getCustomerById,
    createCustomer,
    updateCustomer,
    deactivateCustomer,
    reactivateCustomer,
    getCustomerNetworksIds,
    updateCustomerNetworksIds,
    getAllCustomerNetworksIds,
    updateNetworkStatus,
    deleteNetworkId,
    getAuthorizedNetworksIds,
    updateNetworkIds,
} from '@api/customers';
import {
    deleteAccountById,
    reactivateAccountById,
    moveAccountById,
} from '@api/accounts';

import helpers from '@util/helpers';

const state = {
    customers: [],
    customer: {},
    networkIds: [],
    savedNetworkIds: [],
    networksToAdd: [],
    networksToRemove: [],
    gamNetAuthIds: [],
    authorizedNetworkIds: [],
    networkIDsErrors: null,
};

const mutations = {
    SET_CUSTOMERS(state, customers) {
        state.customers = customers;
    },
    SET_CUSTOMER(state, customer) {
        state.customer = customer;
    },
    SET_SAVED_NETWORK_IDS(state, networkIds) {
        state.savedNetworkIds = networkIds;
        const newNetworksIds = [...networkIds];
        state.networkIds = [...new Set(newNetworksIds)];
    },
    SET_NETWORK_IDS(state, networkIds) {
        state.networkIds = networkIds;
    },
    SET_NETWORKS_TO_ADD(state, networks) {
        const newNetworks = networks.filter(network => !state.savedNetworkIds.includes(network));
        state.networksToAdd = [...newNetworks];
    },
    SET_NETWORKS_TO_REMOVE(state, network) {
        if (Array.isArray(network)) {
            state.networksToRemove = [];
            return;
        }
        const isSaved = state.savedNetworkIds.includes(network);
        const isAdded = state.networksToRemove.includes(network);
        const isAuthorized = state.authorizedNetworkIds.filter(authNetwork => authNetwork.network_id === network).length > 0;

        if ((isSaved && !isAdded) || (isAuthorized && !isAdded)) {
            state.networksToRemove = [...state.networksToRemove, network];
        }

        if (isAuthorized) {
            state.authorizedNetworkIds = state.authorizedNetworkIds.filter(authNetwork => authNetwork.network_id !== network);
        }
    },
    SET_GAM_NET_AUTH_IDS(state, gamNetAuthIds) {
        state.gamNetAuthIds = gamNetAuthIds;
    },

    SET_AUTHORIZED_NETWORK_IDS(state, authorizedNetworkIds) {
        state.authorizedNetworkIds = authorizedNetworkIds;
    },
    DELETE_NETWORK_ID(state, id) {
        state.authorizedNetworkIds = state.authorizedNetworkIds.filter(network => network.id !== id);
    },
    SET_HAS_ERRORS(state, networkIDsErrors) {
        state.networkIDsErrors = networkIDsErrors;
    }
};

const actions = {
    async getCustomers({ commit, rootGetters }, params) {
        try {
            let { data, metadata } = await getCustomers({
                ...rootGetters.queryParams,
                ...params,
            });
            data = data.map(customer => {
                customer.active =
          customer.deleted_at === null || customer.deleted_at === '';
                return customer;
            });
            commit('SET_CUSTOMERS', data);
            commit('SET_PAGINATION', metadata.pagination, { root: true });
        } catch (e) {
            console.log(e);
        }
    },
    async generateCustomerId() {
        try {
            const customerBillingId = await generateCustomerId();
            return customerBillingId;
        } catch (e) {
            console.log(e);
        }
    },
    async getCustomerById({ commit }, customerId) {
        try {
            const { customer } = await getCustomerById(customerId);
            let copyAddress = helpers.deepCopy(customer.address);

            copyAddress = copyAddress
                ? {
                    address: copyAddress.address || '',
                    unit: copyAddress.unit || '',
                    city: copyAddress.city || '',
                    state: copyAddress.state || '',
                    country: copyAddress.country || '',
                    zip_code: copyAddress.zip_code || '',
                }
                : {
                    address: '',
                    unit: '',
                    city: '',
                    state: '',
                    country: '',
                    zip_code: '',
                };

            customer.address = copyAddress;

            if (customer.accounts) {
                customer.accounts = customer.accounts.map(account => {
                    account.active =
            account.deleted_at === null || account.deleted_at === '';
                    return account;
                });
            }

            commit('SET_CUSTOMER', customer);
        } catch (e) {
            console.log('error ', e);
            throw e;
        }
    },
    async createCustomer({ commit }, customer) {
        const params = {
            name: customer.name,
            customer_billing_id: customer.customer_billing_id,
        };

        try {
            const customer = await createCustomer(params);
            commit('SET_CUSTOMER', customer);
        } catch (e) {
            console.log('error ', e);
            throw e;
        }
    },
    async updateCustomer({ dispatch }, customer) {
        try {
            await updateCustomer(customer.customer_id, customer);
            dispatch(
                'setAlertMessage',
                { message: 'Your changes have been saved.' },
                { root: true }
            );
        } catch (e) {
            console.log('error ', e);
            throw e;
        }
    },
    async deactivateCustomer({ dispatch }, customerId) {
        try {
            await deactivateCustomer(customerId);
            dispatch(
                'setAlertMessage',
                { type: 'error', message: 'Customer deactivated.' },
                { root: true }
            );
        } catch (e) {
            console.log(e);
            dispatch(
                'setAlertMessage',
                { type: 'error', message: e.error },
                { root: true }
            );
        }
    },
    async reactivateCustomer({ dispatch }, customerId) {
        try {
            await reactivateCustomer(customerId);
            dispatch(
                'setAlertMessage',
                { message: 'Customer reactivated.' },
                { root: true }
            );
        } catch (e) {
            console.log(e);
            dispatch(
                'setAlertMessage',
                { type: 'error', message: e.error },
                { root: true }
            );
        }
    },
    async getAllActiveCustomers({ commit }) {
        try {
            let { data } = await getCustomers({ activeOnly: true });
            commit('SET_CUSTOMERS', data);
        } catch (e) {
            console.log(e);
        }
    },
    async moveCustomerAccount({ dispatch }, accountOptions) {
        try {
            const { accountId, customerId } = accountOptions;
            await moveAccountById(accountId, customerId);
            dispatch(
                'setAlertMessage',
                { message: 'Your changes have been saved.' },
                { root: true }
            );
        } catch (e) {
            console.log('error ', e);
            dispatch(
                'setAlertMessage',
                { type: 'error', message: e.error },
                { root: true }
            );
        }
    },
    async deactivateCustomerAccount({ dispatch }, accountOptions) {
        try {
            const { accountId } = accountOptions;
            await deleteAccountById(accountId);
            dispatch(
                'setAlertMessage',
                { message: 'Your changes have been saved.' },
                { root: true }
            );
        } catch (e) {
            console.log('error ', e);
            dispatch(
                'setAlertMessage',
                { type: 'error', message: e.error },
                { root: true }
            );
        }
    },
    async reactivateCustomerAccount({ dispatch }, accountOptions) {
        try {
            const { accountId } = accountOptions;
            await reactivateAccountById(accountId);
            dispatch(
                'setAlertMessage',
                { message: 'Your changes have been saved.' },
                { root: true }
            );
        } catch (e) {
            console.log('error ', e);
            dispatch(
                'setAlertMessage',
                { type: 'error', message: e.error },
                { root: true }
            );
        }
    },
    async setNetworkIds({ commit }, networkIds) {
        commit('SET_NETWORK_IDS', networkIds);
    },
    async setSavedNetworkIds({ commit }, networkIds) {
        commit('SET_SAVED_NETWORK_IDS', networkIds);
    },
    async setNetworksToAdd({ commit }, networks) {
        commit('SET_NETWORKS_TO_ADD', networks);

    },
    async setNetworksToRemove({ commit }, network) {
        commit('SET_NETWORKS_TO_REMOVE', network);

    },
    async discardNetworkIdsChanges({ commit, state }) {
        commit('SET_NETWORK_IDS', state.savedNetworkIds);
        commit('SET_NETWORKS_TO_ADD', []);
        commit('SET_NETWORKS_TO_REMOVE', []);
    },
    async loadNetworksIds({ commit }, customerId) {
        try {
            const data = await getCustomerNetworksIds(customerId);
            const networksIds = data.map(network => network.network_id);
            commit('SET_SAVED_NETWORK_IDS', networksIds);

        } catch (e) {
            console.error('Error fetching networks: ', e);
            throw e;
        }
    },
    async updateNetworksIds({ commit, dispatch, getters }, { customerId }) {
        const { networksToAdd, networksToRemove, savedNetworkIds } = getters;
        const body = { networksToAdd, networksToRemove };
        try {
            await updateCustomerNetworksIds(customerId, body);
            dispatch(
                'setAlertMessage',
                { message: 'Your changes have been saved.' },
                { root: true }
            );
            const newNetworkIds = networksToAdd.filter(network => !savedNetworkIds.includes(network) && !networksToRemove.includes(network));
            const newSavedNetworkIds = [...savedNetworkIds.filter((network) => !networksToRemove.includes(network)), ...newNetworkIds]
            commit('SET_SAVED_NETWORK_IDS', newSavedNetworkIds);
            commit('SET_NETWORK_IDS', newSavedNetworkIds);
            commit('SET_NETWORKS_TO_ADD', []);
            commit('SET_NETWORKS_TO_REMOVE', []);
        } catch (e) {
            console.error('Error updating networks: ', e);
            dispatch(
                'setAlertMessage',
                { type: 'error', message: e.message },
                { root: true }
            );
        }
    },
    async loadAllCustomerNetworksIds({ commit, rootGetters }, params ) {
        try {
            const { data, metadata } = await getAllCustomerNetworksIds({
                ...rootGetters.queryParams,
                ...params,
            });
            commit('SET_GAM_NET_AUTH_IDS', data);
            commit('SET_PAGINATION', metadata.pagination, { root: true });
        } catch (e) {
            console.error('Error fetching networks: ', e);
            throw e;
        }
    },
    async updateNetworkIdStatus({ dispatch }, { id, status }) {
        try {
            await updateNetworkStatus(id, status);
            let message  = '';
            if (status === 'APPROVED') {
                message = 'Network ID has been approved.';
            } else if (status === 'DENIED') {
                message = 'Network ID has been denied.';
            }
            dispatch(
                'setAlertMessage',
                { message },
                { root: true }
            );
        } catch (e) {
            console.log('error ', e);
            dispatch(
                'setAlertMessage',
                { type: 'error', message: e.error },
                { root: true }
            );
        }
    },
    async deleteNetworkId({ dispatch, commit }, id ) {
        try {
            await deleteNetworkId(id);
            dispatch(
                'setAlertMessage',
                { message: 'Network ID has been deleted' },
                { root: true }
            );
            commit('DELETE_NETWORK_ID', id);
        } catch (e) {
            console.log('error ', e);
            dispatch(
                'setAlertMessage',
                { type: 'error', message: e.error },
                { root: true }
            );
        }
    },
    async loadAuthorizedNetworksIds({ commit }) {
        try {
            const data = await getAuthorizedNetworksIds();
            commit('SET_AUTHORIZED_NETWORK_IDS', data);
        } catch (e) {
            console.error('Error fetching networks: ', e);
            throw e;
        }
    },
    async addToNetworkIds({ commit, dispatch, getters, rootGetters }) {
        const { networksToAdd, networksToRemove } = getters;
        const body = { networksToAdd, networksToRemove };
        try {
            await updateNetworkIds(body);
            dispatch('loadAuthorizedNetworksIds');
            const userRole = rootGetters.userInformation.role;
            if (userRole === 'system_admin') {
                dispatch(
                    'setAlertMessage',
                    { message: 'Your changes have been saved.' },
                    { root: true }
                );
            } else if (userRole === 'account_admin') {
                dispatch(
                    'setAlertMessage',
                    { message: 'Your GAM Network ID has been submitted for review. If authorized, the new ID will be available to use in 24h or less.' },
                    { root: true }
                );
            }
            commit('SET_NETWORKS_TO_ADD', []);
            commit('SET_NETWORKS_TO_REMOVE', []);
            commit('SET_HAS_ERRORS', null);
        } catch (e) {
            commit('SET_HAS_ERRORS', e.message);
            throw e;
        }
    },
    async setNetworkIdsErrors({ commit }, errors) {
        commit('SET_HAS_ERRORS', errors);
    },

};

const getters = {
    customers: ({ customers }) => customers,
    customer: ({ customer }) => customer,
    customerNameById: ({ customers }) => {
        return customerId => {
            const customer = customers.find(customer => customer.customer_id === customerId);
            return customer ? customer.name : '-';
        };
    },
    networkIds: ({ networkIds }) => networkIds,
    savedNetworkIds: ({ savedNetworkIds }) => savedNetworkIds,
    networksToAdd: ({ networksToAdd }) => networksToAdd,
    networksToRemove: ({ networksToRemove }) => networksToRemove,
    gamNetAuthIds: ({ gamNetAuthIds }) => gamNetAuthIds,
    authorizedNetworkIds: ({ authorizedNetworkIds }) => authorizedNetworkIds,
    networkIDsErrors: ({ networkIDsErrors }) => networkIDsErrors,
};

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true,
};
