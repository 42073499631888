import {
    fetchLineItemJobs,
    fetchTargetingJobs,
    createJob,
    cancelJob,
    getJobById,
} from '@api/gamServices';
import { fetchJobSites, fetchSiteVersion } from '@api/sites';
import {
    mapSiteResults,
    mapGAMBidders,
    formatSelectedBidders,
    formatTargetingConfig,
    mapJobResult,
} from '@util/gamServices';
import router from '@router/router';

export default {
    updateGAMConfig({ commit }, configOptions) {
        commit('SET_GAM_CONFIG', configOptions);
    },
    setSelectedSiteId({ commit }, siteId) {
        commit('SET_SELECTED_SITE_ID', siteId);
    },
    setDefaultBidders({ commit, rootGetters }) {
        const biddersList = mapGAMBidders(rootGetters.bidders);
        commit('SET_DEFAULT_BIDDERS', biddersList);
    },
    // SINGLE MODE
    setSelectedBidder({ commit, dispatch, state }, bidder) {
        dispatch('setDefaultBidders');
        const bidderIndex = state.bidders.findIndex(b => b.text === bidder);
        commit('SET_SELECTED_BIDDER', { ...bidder, bidderIndex });
    },
    // BULK MODE
    setSelectedBidders({ commit, state }, selectedBidders) {
        const updatedBidderList = state.bidders.map(b => {
            const isBidderSelected = selectedBidders.includes(b.value);

            if (isBidderSelected) b.selected = true;
            else b.selected = false;

            return b;
        });

        commit('SET_SELECTED_BIDDERS', updatedBidderList);
    },
    resetGAMConfig({ commit, dispatch }) {
        commit('RESET_GAM_CONFIG');
        dispatch('setDefaultBidders');
    },
    setArePriceBucketsValid({ commit }, isValid) {
        commit('SET_ARE_PRICE_BUCKETS_VALID', isValid);
    },

    async getLineItemJobs({ commit, rootGetters }, params) {
        try {
            const { data, metadata } = await fetchLineItemJobs({
                ...rootGetters.queryParams,
                ...params
            });
            commit('SET_LINE_ITEM_JOBS', data);
            commit('SET_PAGINATION', metadata.pagination, { root: true });
        } catch (e) {
            console.log(e);
        }
    },
    async getTargetingJobs({ commit, rootGetters }, params) {
        try {
            const { data, metadata } = await fetchTargetingJobs({
                ...rootGetters.queryParams,
                ...params
            });
            commit('SET_TARGETING_JOBS', data);
            commit('SET_PAGINATION', metadata.pagination, { root: true });
        } catch (e) {
            console.log(e);
        }
    },

    async retryJob({ dispatch }, job) {
        const body = {
            site_id: job.site_id,
            job_type: job.job_type,
        };

        if (job.job_type === 'ORDER') {
            body.bidderObjects = [
                {
                    name: job.bidder,
                    config: job.config,
                },
            ];
        } else {
            body.config = job.config;
        }

        try {
            await createJob(body);
            dispatch('setAlertMessage', { message: `Success!` }, { root: true });
        } catch (e) {
            console.log(e);
        }
    },

    async cancelJob({ dispatch }, jobId) {
        try {
            await cancelJob(jobId);
            dispatch('setAlertMessage', { message: `Success!` }, { root: true });
        } catch (e) {
            console.log(e);
        }
    },

    async createLineItem({ state, dispatch }) {
        const { selectedSiteId, bidders, config } = state;

        const body = {
            site_id: selectedSiteId,
            job_type: 'ORDER',
            bidderObjects: formatSelectedBidders(bidders, config),
            gamNetworkId: config.dfpNetworkId,
        };

        try {
            await createJob(body);
            dispatch('resetGAMConfig');
            dispatch(
                'setAlertMessage',
                { message: `You've successfully created a line item job!` },
                { root: true }
            );
            router.push({ name: 'gam-services' });
        } catch (e) {
            console.log(e);
        }
    },

    async createTargeting({ state, dispatch }) {
        const { selectedSiteId, config } = state;

        const body = {
            site_id: selectedSiteId,
            job_type: 'TARGETING',
            config: formatTargetingConfig(config),
            gamNetworkId: config.dfpNetworkId,
        };

        try {
            await createJob(body);
            dispatch('resetGAMConfig');
            dispatch(
                'setAlertMessage',
                { message: `You've successfully created a targeting job!` },
                { root: true }
            );
            router.push({ name: 'targeting-history' });
        } catch (e) {
            console.log(e);
        }
    },

    async getJobSites({ commit }) {
        try {
            const data = await fetchJobSites();
            const mappedSites = mapSiteResults(data);
            commit('SET_JOB_SITES', mappedSites);
        } catch (e) {
            console.log(e);
        }
    },

    async getJob({ commit }, jobId) {
        try {
            const { lineItemJob } = await getJobById(jobId);
            const mappedJob = mapJobResult(lineItemJob);
            commit('SET_SELECTED_JOB', mappedJob);
        } catch (e) {
            console.log(e);
            throw e;
        }
    },

    async fetchSiteVersion({ commit }, params) {
        try {
            const { versionId } = params;
            const { version } = await fetchSiteVersion(versionId);
            commit('SET_VERSION', version);
        } catch (e) {
            console.log(e);
        }
    },
    resetSelectedJob({ commit }) {
        commit('SET_SELECTED_JOB', {});
    },
    setFetchInterval({ commit }, intervalOptions) {
        commit('SET_FETCH_INTERVAL', intervalOptions);
    },
    resetFetchInterval({ commit }) {
        commit('RESET_FETCH_INTERVAL');
    },
};
