const mutations = {
    SET_CONSENT_MANAGEMENT(state, consentManagementOptions) {
        const { category, key, value } = consentManagementOptions;

        if (!category) {
            state.version.config.consentManagerVersion2[key] = value;
        } else {
            state.version.config.consentManagerVersion2[category][key] = value;
        }

        // MSPA requires GPP, if GPP is off, force MSPA to be disabled as well
        if (key === 'gppSignal' && value === 'never') {
            state.version.config.consentManagerVersion2.settings.mspaSignal = 'never';
        }
    },
    SET_CONSENT_MANAGEMENT_V3SETTINGS(state, options) {
        const { category, key } = options;
        const value = Number(options.value); // Convert to a number

        // ensure this exists.
        // ideally we would create a migration script for this, but these v3Settings keys are temporal
        state.version.config.consentManagerVersion2.v3Settings = state.version.config.consentManagerVersion2.v3Settings || {};
        const v3Settings = state.version.config.consentManagerVersion2.v3Settings;
        v3Settings.gdpr = v3Settings.gdpr || {};
        v3Settings.gpp = v3Settings.gpp || {};

        v3Settings[category][key] = isNaN(value) ? 8000 : value;
    },
};

const actions = {

    // CONSENT MANAGEMENT
    setConsentManagement({ commit }, consentManagementOptions) {
        commit('SET_CONSENT_MANAGEMENT', consentManagementOptions);
    },
    setConsentManagementV3Settings({ commit }, options) {
        commit('SET_CONSENT_MANAGEMENT_V3SETTINGS', options);
    },
};

const getters = {
    consentManagement: ({ version }) =>
        version.config.consentManagerVersion2 || {},
};

export default {
    mutations,
    actions,
    getters,
};
