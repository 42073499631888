import Role from '@enums/role';

const routes = {
    mode: 'history',
    routes: [
        {
            path: '/callback',
            name: 'callback',
            component: () => import('@views/Callback'),
        },
        {
            path: '/legacy-login',
            name: 'legacy-login',
            component: () => import('@views/AuthenticationView'),
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('@views/AuthenticationView'),
        },
        {
            path: '/sso',
            name: 'sso',
            component: () => import('@views/AuthenticationView'),
        },
        {
            path: '/reset-password',
            name: 'reset-password',
            component: () => import('@views/AuthenticationView'),
        },
        {
            path: '/',
            name: 'mainView',
            component: () => import('@views/MainView'),
            redirect: { name: 'sites' },
            children: [
                {
                    path: '/sites',
                    component: () => import('@views/Sites'),
                    redirect: { name: 'sites' },
                    meta: {
                        authorize: [Role.SYSTEM_ADMIN, Role.USER, Role.USER_NO_DEPLOYMENT, Role.ACCOUNT_ADMIN],
                    },
                    children: [
                        {
                            path: '/',
                            name: 'sites',
                            component: () => import('@views/sites/Index'),
                            meta: {
                                authorize: [
                                    Role.SYSTEM_ADMIN,
                                    Role.USER,
                                    Role.USER_NO_DEPLOYMENT,
                                    Role.ACCOUNT_ADMIN,
                                ],
                            },
                        },
                        {
                            path: ':siteId',
                            name: 'site-overview',
                            component: () => import('@views/sites/SiteOverview'),
                            meta: {
                                authorize: [
                                    Role.SYSTEM_ADMIN,
                                    Role.USER,
                                    Role.USER_NO_DEPLOYMENT,
                                    Role.ACCOUNT_ADMIN,
                                ],
                            },
                        },
                        {
                            path: ':siteId/version/:versionId',
                            name: 'site-version',
                            component: () => import('@views/sites/SiteVersion'),
                            meta: {
                                authorize: [
                                    Role.SYSTEM_ADMIN,
                                    Role.USER,
                                    Role.USER_NO_DEPLOYMENT,
                                    Role.ACCOUNT_ADMIN,
                                ],
                            },
                        },
                        {
                            path: ':siteId/ads-txt/:adsTxtId',
                            name: 'ads-txt-overview',
                            component: () => import('@views/AdsTxtOverview'),
                            meta: {
                                authorize: [
                                    Role.SYSTEM_ADMIN,
                                    Role.USER,
                                    Role.USER_NO_DEPLOYMENT,
                                    Role.ACCOUNT_ADMIN,
                                ],
                            },
                        }
                    ],
                },
                {
                    path: '/gam-services',
                    name: 'gam-services',
                    component: () => import('@views/GamServices'),
                    redirect: { name: 'line-item-history' },
                    meta: { authorize: [Role.SYSTEM_ADMIN] },
                    children: [
                        {
                            path: 'line-item-history',
                            name: 'line-item-history',
                            component: () => import('@views/gamServices/Index'),
                            props: { selectedTab: 'line-item-history' },
                            meta: { authorize: [Role.SYSTEM_ADMIN] },
                        },
                        {
                            path: 'targeting-history',
                            name: 'targeting-history',
                            component: () => import('@views/gamServices/Index'),
                            props: { selectedTab: 'targeting-history' },
                            meta: { authorize: [Role.SYSTEM_ADMIN] },
                        },
                        {
                            path: 'create-line-items',
                            name: 'create-line-items',
                            component: () => import('@views/gamServices/CreateLineItems'),
                            meta: { authorize: [Role.SYSTEM_ADMIN] },
                        },
                        {
                            path: 'add-remove-targeting',
                            name: 'add-remove-targeting',
                            component: () => import('@views/gamServices/AddRemoveTargeting'),
                            meta: { authorize: [Role.SYSTEM_ADMIN] },
                        },
                        {
                            path: 'job-overview/:jobId',
                            name: 'job-overview',
                            component: () => import('@views/gamServices/JobOverview'),
                            meta: { authorize: [Role.SYSTEM_ADMIN] },
                            props: true,
                        },
                    ],
                },
                {
                    path: '/prebid-id-uploads',
                    component: () => import('@views/PrebidIdUploads'),
                    redirect: { name: 'prebid-id-uploads' },
                    meta: { authorize: [Role.SYSTEM_ADMIN, Role.ACCOUNT_ADMIN] },
                    children: [
                        {
                            path: '/',
                            name: 'prebid-id-uploads',
                            component: () => import('@views/idUploads/PrebidIdUploadsView'),
                            meta: { authorize: [Role.SYSTEM_ADMIN, Role.ACCOUNT_ADMIN] },
                        },
                        {
                            path: 'new-id-upload',
                            name: 'new-id-upload',
                            component: () => import('@views/idUploads/NewIdUpload'),
                            meta: { authorize: [Role.SYSTEM_ADMIN, Role.ACCOUNT_ADMIN] },
                        },
                        {
                            path: '/prebid-id-upload/:uploadId',
                            name: 'id-upload-details',
                            component: () => import('@views/idUploads/IdUploadOverview'),
                            meta: { authorize: [Role.SYSTEM_ADMIN, Role.ACCOUNT_ADMIN] },
                        }
                    ]
                },
                {
                    path: '/htl-exec',
                    name: 'htl-exec',
                    component: () => import('@views/htlExec/HtlExec'),
                    meta: {
                        authorize: [Role.SYSTEM_ADMIN, Role.USER, Role.USER_NO_DEPLOYMENT, Role.ACCOUNT_ADMIN],
                    },
                },
                {
                    path: '/networks',
                    component: () => import('@views/networks/Index'),
                    name: 'networks',
                    meta: { authorize: [Role.SYSTEM_ADMIN] },
                },
                {
                    path: '/ad-tech-services',
                    component: () => import('@views/adTechServices/Index'),
                    name: 'ad-tech-services',
                    meta: { authorize: [Role.SYSTEM_ADMIN] },
                },
                {
                    path: '/builds',
                    component: () => import('@views/Builds'),
                    meta: { authorize: [Role.SYSTEM_ADMIN] },
                    children: [
                        {
                            path: '/',
                            name: 'builds',
                            component: () => import('@views/builds/Index'),
                            meta: { authorize: [Role.SYSTEM_ADMIN] },
                        },
                        {
                            path: '/builds/:versionId',
                            name: 'build-details',
                            component: () => import('@views/builds/BuildDetails'),
                            meta: {
                                authorize: [
                                    Role.SYSTEM_ADMIN,
                                    Role.USER,
                                    Role.USER_NO_DEPLOYMENT,
                                    Role.ACCOUNT_ADMIN,
                                ],
                            },
                        },
                    ],
                },
                {
                    path: '/gam-services-history',
                    name: 'gam-services-history',
                    component: () => import('@views/GamServicesHistory'),
                    meta: { authorize: [Role.SYSTEM_ADMIN] },
                    redirect: { name: 'admin-line-item-history' },
                    children: [
                        {
                            path: 'line-item-history',
                            name: 'admin-line-item-history',
                            component: () => import('@views/gamServices/admin/Index'),
                            props: { selectedTab: 'line-item-history' },
                            meta: { authorize: [Role.SYSTEM_ADMIN] },
                        },
                        {
                            path: 'targeting-history',
                            name: 'admin-targeting-history',
                            component: () => import('@views/gamServices/admin/Index'),
                            props: { selectedTab: 'targeting-history' },
                            meta: { authorize: [Role.SYSTEM_ADMIN] },
                        },
                    ]
                },
                {
                    path: '/users',
                    component: () => import('@views/Users'),
                    redirect: { name: 'users' },
                    meta: { authorize: [Role.SYSTEM_ADMIN, Role.ACCOUNT_ADMIN] },
                    children: [
                        {
                            path: '/',
                            name: 'users',
                            component: () => import('@views/users/Index'),
                            meta: { authorize: [Role.SYSTEM_ADMIN, Role.ACCOUNT_ADMIN] },
                        },
                        {
                            path: ':userId',
                            name: 'user-config',
                            component: () => import('@views/users/UserConfig'),
                            meta: { authorize: [Role.SYSTEM_ADMIN, Role.ACCOUNT_ADMIN] },
                        },
                    ],
                },
                {
                    path: '/accounts',
                    component: () => import('@views/Accounts'),
                    children: [
                        {
                            path: '/',
                            name: 'accounts',
                            component: () => import('@views/accounts/Index'),
                            meta: { authorize: [Role.SYSTEM_ADMIN, Role.ACCOUNT_ADMIN] },
                        },
                        {
                            path: ':accountId',
                            name: 'account-config',
                            component: () => import('@views/accounts/AccountConfig'),
                            meta: {
                                authorize: [
                                    Role.SYSTEM_ADMIN,
                                    Role.USER,
                                    Role.USER_NO_DEPLOYMENT,
                                    Role.ACCOUNT_ADMIN,
                                ],
                            },
                        },
                    ],
                },
                {
                    path: '/settings',
                    component: () => import('@views/Settings'),
                    children: [
                        {
                            path: '/',
                            name: 'settings',
                            component: () => import('@views/settings/Index'),
                            meta: {
                                authorize: [
                                    Role.SYSTEM_ADMIN,
                                    Role.USER,
                                    Role.USER_NO_DEPLOYMENT,
                                    Role.ACCOUNT_ADMIN,
                                ],
                            },
                        },
                    ],
                },
                {
                    path: '/error',
                    name: 'error',
                    component: () => import('@views/ErrorView'),
                    props: true,
                },
                {
                    path: '/customers',
                    component: () => import('@views/Customers'),
                    children: [
                        {
                            path: '/',
                            name: 'customers',
                            component: () => import('@views/customers/Index'),
                            meta: { authorize: [Role.SYSTEM_ADMIN] },
                        },
                        {
                            path: ':customerId',
                            name: 'customer-config',
                            component: () => import('@views/customers/CustomerConfig'),
                            meta: { authorize: [Role.SYSTEM_ADMIN] },
                        },
                        {
                            path: '/gam-network-id-authorization',
                            name: 'gam-network-id-authorization',
                            component: () => import('@views/customers/GamNetworkIdAuthorization'),
                            meta: { authorize: [Role.SYSTEM_ADMIN] },
                        },
                    ],
                },
            ],
        },
    ],
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
};

export default routes;
