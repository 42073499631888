import helpers from '@util/helpers';
import versionHelpers from '@util/versionHelpers';

export const mapSiteResults = sites => {
    const mappedSites = sites.map(site => ({
        value: site.site_id,
        text: site.domain,
        granularity: site.config.prebid.granularity,
        customGranularity: site.config.prebid.customGranularity,
        build_version: site.build_version,
        enableSendAllBids: site.config.prebid.enableSendAllBids,
        gamNetworkId: site.config.dfpNetworkId,
    }));

    return mappedSites;
};

export const mapGAMBidders = bidders => {
    const copiedBidders = helpers.deepCopy(bidders);
    const mappedBidders = copiedBidders
        .sort(helpers.dynamicSort('name'))
        .map((bidder, index) => ({
            value: index,
            text: bidder.name,
            selected: false,
            safeFrame: bidder.is_safeframe,
        }));

    return mappedBidders;
};

export const formatSelectedBidders = (bidders, config) => {
    let selectedBidders = bidders.filter(b => b.selected);

    const formattedBidders = selectedBidders.map(b => {
        const bidderObject = {};
        bidderObject.name = b.text;
        bidderObject.config = helpers.deepCopy(formatLineItemConfig(config));

        // SET CREATIVE TYPE
        if (!bidderObject.config.creativeType) {
            if (b.safeFrame) {
                bidderObject.config.creativeType = 'PREBID_SAFEFRAME';
            } else {
                bidderObject.config.creativeType = 'PREBID_UNSAFE';
            }
        }

        // SET ORDER NAME
        if (!bidderObject.config.orderName) {
            bidderObject.config.orderName = `Prebid_${b.text}`;
        }

        // SET PLACEMENTS
        if (bidderObject.config.placements.length === 0) {
            bidderObject.config.placements = 'Prebid';
        } else {
            bidderObject.config.placements = bidderObject.config.placements.join();
        }

        // SET SIZES
        if (bidderObject.config.sizes.length === 0) {
            bidderObject.config.sizes = '1x1,1x2,728x90,250x250,160x600,300x600,970x90,970x250,300x250,320x50,300x50,300x100,336x280,480x320';
        } else {
            bidderObject.config.sizes = bidderObject.config.sizes.join();
        }

        return bidderObject;
    });

    return formattedBidders;
};

export const formatLineItemConfig = config => {
    // clean add targeting params
    const { addTargeting, key, operator, values, ...lineItemParams } = config; // eslint-disable-line no-unused-vars
    return lineItemParams;
};

export const formatTargetingConfig = config => {
    // return only targeting params
    const { orderName, addTargeting, key, operator, values } = config;
    return { orderName, addTargeting, key, operator, values };
};

export const mapJobResult = job => {
    job.config.granularityValue = job.config.customGranularity;
    if (job.config.granularity !== 'custom') {
        job.config.granularityValue =
      versionHelpers.granularityValues[job.config.granularity];
    }
    delete job.config.customGranularity;
    job.config = Object.keys(job.config)
        .sort()
        .reduce((r, k) => ((r[k] = job.config[k]), r), {});
    return job;
};
