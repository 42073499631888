import regex from '@util/regex';

/**
 * Checks if an Amazon configuration is valid.
 *
 * @param {Object} config - The config object containing the Amazon configuration object to validate.
 * @returns {boolean} Returns true if the configuration has a valid pubId, false otherwise.
 */
export function isAmazonValid(config) {
    const {options, amazon} = config;

    if (amazon.pubId && !regex.alpha_num_dash.test(amazon.pubId)) {
        return false;
    }

    if (options.hasAmazonBidCaching && !isValidJsonObject(amazon.hashmapJsonString)) {
        return false;
    }

    return true;
}

function isValidJsonObject(str) {
    try {
        const obj = JSON.parse(str);
        return obj !== null && typeof obj === 'object' && !Array.isArray(obj);
    } catch (e) {
        return false;
    }
}
