// ADDING RULES
// https://vee-validate.logaretm.com/v3/guide/basics.html#adding-rules

// AVAILABLE RULES
// https://vee-validate.logaretm.com/v3/guide/rules.html#rules

import { extend } from 'vee-validate';
import {
    email,
    required,
    alpha,
    alpha_dash,
    numeric,
    min_value,
    max_value,
    double,
    excluded,
    max,
    between,
    alpha_num,
    is,
} from 'vee-validate/dist/rules';
import regex from '@util/regex';
import helpers from '@util/moduleValidationHelpers';
import { isPrebidVersionValid } from '../util/validation/prebid';
import { isValidCss, isValidJavascript, isValidJson } from '../util/validation/utils';
import semver from 'semver';

extend('required', {
    validate(value) {
        return Array.isArray(value)
            ? {
                valid: value.length > 0,
                required: true,
            }
            : required.validate(value);
    },
    computesRequired: true,
    message: 'This field is required.',
});

extend('required_if_set', {
    validate(value, { argValue }) {
        return !(value === '' && argValue !== '');
    },
    computesRequired: true,
    params: ['argValue'],
    message: 'This field is required if {argValue} is set.',
})

extend('required_unless', {
    validate(value, { argValue }) {
        return argValue !== '' || value !== '';
    },
    computesRequired: true,
    params: ['argValue', 'argName'],
    message: 'Please fill out this field, {argValue} or both.',
});

extend('prohibited_if', {
    validate(value, { argValue }) {
        return ((argValue !== '' && value === '') || (argValue === '' && value !== '')) && !(value === '' && argValue === '');
    },
    computesRequired: true,
    params: ['argValue'],
    message: 'Please fill out either this field or {argValue}, but not both.',
});

extend('css', {
    validate(value) {
        if (typeof value === 'object') {
            value = value?.value;
        }
        return isValidCss(value);
    },
    message: 'Invalid CSS.',
});

extend('javascript', {
    validate(value) {
        return isValidJavascript(value);
    },
    message: 'Invalid JavaScript.',
});

extend('json', {
    validate(value) {
        return isValidJson(value);
    },
    message: 'Invalid JSON.',
});

extend('email', {
    ...email,
    message: 'This field should contain a valid email address.',
});

extend('alpha', {
    ...alpha,
    message: 'This field should contain only alphabetical characters.',
});

extend('alpha_dash', {
    ...alpha_dash,
    message:
    'This field should contain only alphabetical, numercial, "-" or "_" characters. No spaces.',
});

extend('alpha_dash_array', {
    validate(values) {
        return values.every((v) => regex.alpha_num_dash.test(v));
    },
    message: 'Each item should contain only alphabetical, numercial, "-" or "_" characters and no spaces.',
});

extend('numeric', {
    ...numeric,
    message:
    'This field should contain only numerical characters and no decimals.',
});

extend('single_value', {
    validate(value) {
        try {
            const separated = value.toString().split(' ');
            return separated.length === 1;
        } catch (e) {
            return false;
        }
    },
    message: 'This field should contain only one value.',
});

extend('placements', {
    validate(placements) {
        return placements.every(placement =>
            regex.alpha_num_dash_underscore_space.test(placement)
        );
    },
    message:
    'Each tag should contain only alphabetical, numercial, "-", "_" or space characters.',
});

extend('alpha_dash_comma', {
    validate(value) {
        return regex.alpha_dash_comma.test(value);
    },
    message:
    'The field only accepts alphanumerical characters or comma.',
});

extend('sizes', {
    validate(sizes) {
        if (Array.isArray(sizes))
            return sizes.every(size => regex.sizes.test(size));
        return regex.sizes.test(sizes);
    },
    message:
    'Please enter the correct size format, such as "300x250" or "728x90". Also accepted is the word "fluid".',
});

extend('videoSizes', {
    validate(sizes) {
        if (Array.isArray(sizes))
            return sizes.every(size => regex.videoSizes.test(size));
        return regex.videoSizes.test(sizes);
    },
    message:
    'Please enter the correct size format, such as "640x480v" or "400x300v". Also accepted is the word "fluid".',
});

extend('price_bucket', {
    validate(value) {
        return value;
    },
    message: 'This price bucket configuration is invalid.',
});

extend('no_sql', {
    validate(value) {
        if (value === 'rf' || value === 'rm') return false;
        return true;
    },
    message: 'This combination of letters is not allowed.',
});

extend('no_sql_array', {
    validate(array) {
        if (array.some(value => value === 'rf' || value === 'rm')) return false;
        return true;
    },
    message: 'This combination of letters is not allowed.',
});

extend('domain', {
    validate(value) {
        return regex.domain.test(value);
    },
    message: 'Domain not valid.',
});

extend('lower_case_only', {
    validate(value) {
        return regex.lower_case_only.test(value);
    },
    message: 'Value can contain lower case letters only.',
});

extend('uuid', {
    validate(value) {
        return regex.uuid.test(value);
    },
    message: 'Value should be in format xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx.',
});

extend('version_number', {
    validate(value) {
        return regex.version_number.test(value);
    },
    message: 'Value should be in Semantic Versioning format x.y.z',
});

extend('is_valid_version', {
    validate(value) {
        return isPrebidVersionValid(value);
    },
    message: 'This version number is invalid.',
});

extend('min_value', {
    ...min_value,
    message: 'Value should be numeric and not less than {min}.',
});

extend('max_value', {
    ...max_value,
    message: 'Value should be numeric and not greater than {max}.',
});

extend('double', {
    ...double,
    message: 'Value should be a valid decimal number.',
});

extend('double_with_decimal', {
    ...double,
    validate: value => regex.two_decimal.test(value),
    message: 'Value should be a digit with {decimals} decimals.',
});

extend('excluded', {
    ...excluded,
    message: 'Value should be unique.',
});

extend('numeric_dot', {
    validate(value) {
        return regex.numeric_dot.test(value);
    },
    message:
    'This field should contain only numerical characters or "." character.',
});

extend('oneOf', {
    validate(value, { array }) {
        return array.includes(value.toString());
    },
    params: ['array'],
    message: 'This field can have the following values: {array}.',
});

extend('is_true', {
    validate(value) {
        return value;
    },
    message: 'This value is invalid.',
});

extend('max', {
    ...max,
    message: 'Value should not be longer than {length} characters.',
});

extend('url', {
    validate(value) {
        return regex.url.test(value);
    },
    message: 'Value is not a valid url.',
});

extend('http_url', {
    validate(value) {
        return regex.http_url.test(value);
    },
    message: 'The URL value must start with "https://" or "http://".',
});

extend('css_selector', {
    validate(value) {
        return helpers.isValidCssSelector(value);
    },
    message: 'Value must be valid CSS selector.',
});

extend('alpha_num_dash_slash_space', {
    validate(value) {
        return regex.alpha_num_dash_slash_space.test(value);
    },
    message:
    'Value can contain alphanumeric, underscore, dash, slash and space characters.',
});

extend('alpha_num_dash_slash_dot', {
    validate(value) {
        return regex.alpha_num_dash_slash_dot.test(value);
    },
    message:
    'Value can contain alphanumeric, underscore, dash, slash, and dot characters.',
});

extend('alpha_num_dash_dot', {
    validate(value) {
        return regex.alpha_num_dash_dot.test(value);
    },
    message:
    'Value can contain alphanumeric, underscore, slash, and dot characters.',
});

extend('alpha_num_dash', {
    validate(value) {
        return regex.alpha_num_dash.test(value);
    },
    message:
    'Value can contain alphanumeric and dash characters.',
});

extend('two_numbers_separated_by_comma', {
    validate(value) {
        return regex.two_numbers_separated_by_comma.test(value);
    },
    message: 'Value can contain numeric values separated by comma.',
});

extend('cookie_name', {
    validate(value) {
        return regex.cookie_name.test(value);
    },
    message: 'Value must be a valid RFC 6265 cookie name.',
});

extend('alpha_num_dash_slash', {
    validate(value) {
        return regex.alpha_num_dash_slash.test(value);
    },
    message:
    'This field should contain only alphabetical, numercial, "-", "_" or "/" characters. No spaces.',
});

extend('alpha_num_underscore', {
    validate(value) {
        return regex.alpha_num_underscore.test(value);
    },
    message:
    'Value can contain alphanumeric and underscore characters.',
});

extend('alpha_num_underscore_dash', {
    validate(value) {
        return regex.alpha_num_underscore_dash.test(value);
    },
    message:
    'Value can contain alphanumeric, dash and underscore characters.',
});

extend('size', {
    validate(value) {
        return regex.size.test(value);
    },
    message: 'Value must be in widthxheight format.',
});

extend('size_mapping_sizes', {
    validate(sizes) {
        const invalidSize = sizes.find(
            size => !regex.size.test(size) && !['fluid', 'native'].includes(size)
        );
        return invalidSize === undefined;
    },
    message: 'All sizes should be in format widthxheight.',
});

extend('size_mapping_non_zero', {
    validate(sizes) {
        const invalidSize = sizes.find(size => size.split('x').some(s => s.startsWith('0')));
        return invalidSize === undefined;
    },
    message: 'Width and height must not start with 0.',
})

extend('between', {
    ...between,
    message: (fieldName, placeholders) => {
        return `Number should be between ${placeholders.min} and ${placeholders.max}.`;
    },
});

extend('is_param_true', {
    validate(value, { boolean }) {
        return boolean;
    },
    params: ['boolean'],
});

extend('alpha_num', {
    ...alpha_num,
    message: 'This field should contain only alphanumeric characters.',
});

extend('gam_ad_unit', {
    validate(value) {
        return regex.gam_ad_unit.test(value);
    },
    message:
    'Only letters, numbers, underscores, hyphens, periods, asterisks, forward slashes, back slashes, exclamations, left angle brackets, colons and parentheses are allowed.',
});

extend('alpha_num_dash_space', {
    validate(value) {
        return regex.alpha_num_dash_space.test(value);
    },
    message: 'Value can contain alphanumeric, dash and space characters.',
});

extend('strong_password', {
    validate(value) {
        return (
            regex.strong_password.test(value) &&
      !/\s/.test(value) &&
      !value.includes('.@')
        );
    },
    message: 'The value does not meet the password policy requirements.',
});

extend('repeat_password', {
    ...is,
    message: 'Repeat password is not same as input password.',
});

extend('alpha_num_dash_underscore_space', {
    validate(value) {
        return regex.alpha_num_dash_underscore_space.test(value);
    },
    message:
    'Value can contain alphanumeric, dash, underscore and space characters.',
});

extend('authorized_domains', {
    validate(domains) {
        const invalidDomain = domains.find(
            domain => !regex.domain.test(domain) && !regex.url.test(domain)
        );
        return invalidDomain === undefined;
    },
    message: 'All domains should be valid domains.',
});

extend('unique_authorized_domains', {
    validate(domains) {
        const duplicates = domains.filter(
            (domain, index) => domains.indexOf(domain) !== index
        );
        return duplicates.length === 0;
    },
    message: 'Domains should be unique.',
});

extend('override_domain', {
    validate(domain) {
        return regex.domain.test(domain[0]);
    },
    message: 'Value should be a valid domain.',
});

extend('only_one', {
    validate(value) {
        return value.length === 1;
    },
    message: 'Only one value is allowed.'
})

extend('numeric_or_double', {
    validate(value) {
        return regex.numeric_or_double.test(value);
    },
    message: 'Value should be valid whole or decimal number.',
});

extend('required_html_text', {
    validate(value) {
        let dummyHtml = document.createElement('html');
        dummyHtml.innerHTML = value;
        return dummyHtml.textContent !== '';
    },
    message: 'This field can not be empty.',
});

extend('no_sql_in_html_text', {
    validate(value) {
        let dummyHtml = document.createElement('html');
        dummyHtml.innerHTML = value;
        return dummyHtml.textContent !== 'rf' && dummyHtml.textContent !== 'rm';
    },
    message: 'This combination of letters is not allowed.',
});

extend('device_excluded', {
    ...excluded,
    message: 'The selected device is already configured for this ad slot.',
});

extend('max_viewport_value', {
    validate(minViewport, { maxValue }) {
        const minValue = parseInt(minViewport.split('x')[0], 10);
        return minValue < maxValue;
    },
    params: ['maxValue'],
    message: 'The min viewport should be less than 3000.',
});

extend('is_all_viewports_exclusive', {
    validate(value, { isExclusive }) {
        return isExclusive;
    },
    params: ['isExclusive'],
    message:
    'The device “All_Viewports” covers all device types and cannot be used along other devices in the same slot. If “All_Viewports” is selected it must be the only device for the slot.',
});

extend('alpha_num_dot_underscore_slash_dash_space', {
    validate(value) {
        return regex.alpha_num_dot_underscore_slash_dash_space.test(value);
    },
    message:
    'Value can contain alphanumeric, dot, underscore, slash, dash and space characters.',
});

extend('alpha_num_dot_underscore_slash_dash_at', {
    validate(value) {
        return regex.alpha_num_dot_underscore_slash_dash_at.test(value);
    },
    message:
    'Value can contain alphanumeric, dot, underscore, slash, dash and "at" characters.',
});

extend('alpha_num_dot_underscore_slash_dash', {
    validate(value) {
        return regex.alpha_num_dot_underscore_slash_dash.test(value);
    },
    message:
    'Value can contain alphanumeric, dot, underscore, slash and dash characters.',
});

extend('alpha_num_space', {
    validate(value) {
        return regex.alpha_num_space.test(value);
    },
    message: 'Value can contain alphanumeric or space characters.',
});

extend('address', {
    validate(value) {
        return regex.address.test(value);
    },
    message: 'Valid characters for address are: Alphanumeric, space, slashes, underscore, dash, hashtag, period or comma',
});

extend('phoneFormat', {
    validate(value, { regex }) {
        return regex.test(value);
    },
    params: ['regex', 'format'],
    message: 'Value should match {format} format.',
});

extend('numeric_allow_negative', {
    validate(value) {
        return regex.numeric_allow_negative.test(value);
    },
    message: 'Value should be valid whole number, positive or negative.',
});

extend('required_positive_number', {
    validate(value) {
        return {
            valid: required.validate(value).valid && parseFloat(value) !== 0,
            required: true,
        };
    },
    computesRequired: true,
    message: 'This field is required.',
});

extend('positive_numeric_or_double', {
    validate(value) {
        return regex.numeric_or_double.test(value) && parseFloat(value) >= 0;
    },
    message: 'Value should be a positive decimal number.',
});

extend('semver', {
    validate(value) {
        return semver.coerce(value) !== null;
    },
    message: 'Value should be a valid semantic version.',
});

extend('semver_permissive', {
    validate(value) {
        const validPrefixes = ['zd', 'v3'];
        const hasValidPrefix = validPrefixes.some(prefix => value.toLowerCase().startsWith(prefix));

        return value === 'master' || hasValidPrefix || semver.coerce(value) !== null;
    },
    message: 'Value should be a valid semantic version or "master".',
});
