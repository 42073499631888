import { authorizedAxios } from '@config/axios';

export const getCustomers = params =>
    authorizedAxios.get('/api/v1/customers', { params });
export const generateCustomerId = () =>
    authorizedAxios.get('/api/v1/customers/generate-id');
export const getCustomerById = customerId =>
    authorizedAxios.get(`/api/v1/customers/${customerId}`);
export const createCustomer = body =>
    authorizedAxios.post('/api/v1/customers', body);
export const updateCustomer = (customerId, body) =>
    authorizedAxios.put(`/api/v1/customers/${customerId}`, body);
export const deactivateCustomer = customerId =>
    authorizedAxios.delete(`/api/v1/customers/${customerId}`);
export const reactivateCustomer = customerId =>
    authorizedAxios.put(`/api/v1/customers/${customerId}/reactivate`);
export const getCustomerNetworksIds = customerId =>
    authorizedAxios.get(`/api/v1/gam-network-ids/${customerId}`);
export const updateCustomerNetworksIds = (customerId, body) =>
    authorizedAxios.put(`/api/v1/gam-network-ids/${customerId}`, body);
export const getAllCustomerNetworksIds = params =>
    authorizedAxios.get(`/api/v1/gam-network-ids`, { params });
export const updateNetworkStatus = (id, status) =>
    authorizedAxios.patch(`/api/v1/gam-network-ids/${id}/status`, { status });
export const deleteNetworkId = id =>
    authorizedAxios.delete(`/api/v1/gam-network-ids/${id}`);
export const getAuthorizedNetworksIds = () =>
    authorizedAxios.get(`/api/v1/gam-network-ids/authorized`);
export const updateNetworkIds = (body) =>
    authorizedAxios.put(`/api/v1/gam-network-ids`, body);
