import Vue from 'vue';

const DEFAULT_VALIDATION_ALERT = {
    message: 'INPUT FIELD ERROR',
    description: 'Invalid characters in a parameter input field.',
};

const state = {
    errorSubtabs: [],
    warningSubtabs: [],
    errorVendors: [],
    errorSlots: [],
    warningSlots: [],
    slotNames: {},
    disVendors: [],
    isVersionValid: true,
    validationMessages: [],
    validationAlert: {
        message: '',
        description: '',
    },
    prebidConfigTabsWithErrors: [],
    prebidConfigTabsWithWarnings: [],
    prebidBiddersWithErrors: {},
    prebidBiddersWithWarnings: {},
    prebidGroupsWithInvalidBidderParams: {},
    prebidGroupsWithEmptyBidderParams: {},
    incompatibleIdentityModules: [],
};

const mutations = {
    SET_IS_VERSION_VALID(state, isVersionValid) {
        state.isVersionValid = isVersionValid;
    },
    SET_VALIDATION_MESSAGES(state, messages) {
        state.validationMessages = messages;
    },
    SET_VALIDATION_ALERT_MESSAGE(state, messageOptions) {
        const { message, description } = messageOptions;
        state.validationAlert.message = message;
        state.validationAlert.description = description;
    },
    CLEAR_VALIDATION_ALERT_MESSAGE(state) {
        state.validationAlert.message = '';
        state.validationAlert.description = '';
    },
    SET_ERROR_SUBTABS(state, subtabs) {
        state.errorSubtabs = subtabs;
    },
    ADD_ERROR_SUBTAB(state, subtab) {
        const index = state.errorSubtabs.findIndex(st => st === subtab);
        if (index === -1) {
            state.errorSubtabs.push(subtab);
        }
    },
    REMOVE_ERROR_SUBTAB(state, subtab) {
        const index = state.errorSubtabs.findIndex(st => st === subtab);
        if (index !== -1) {
            state.errorSubtabs.splice(index, 1);
        }
    },
    ADD_ERROR_VENDOR(state, vendorKey) {
        if (!state.errorVendors.includes(vendorKey)) {
            state.errorVendors.push(vendorKey);
        }
    },
    REMOVE_ERROR_VENDOR(state, vendorKey) {
        const index = state.errorVendors.indexOf(vendorKey);
        if (index !== -1) {
            state.errorVendors.splice(index, 1);
        }
    },
    ADD_DIS_VENDOR(state, vendorKey) {
        if (!state.disVendors.includes(vendorKey)) {
            state.disVendors.push(vendorKey);
        }
    },
    REMOVE_DIS_VENDOR(state, vendorKey) {
        const index = state.disVendors.indexOf(vendorKey);
        if (index !== -1) {
            state.disVendors.splice(index, 1);
        }
    },
    SET_WARNING_SUBTABS(state, subtabs) {
        state.warningSubtabs = subtabs;
    },
    ADD_WARNING_SUBTAB(state, subtab) {
        const index = state.warningSubtabs.findIndex(st => st === subtab);
        if (index === -1) {
            state.warningSubtabs.push(subtab);
        }
    },
    REMOVE_WARNING_SUBTAB(state, subtab) {
        const index = state.warningSubtabs.findIndex(st => st === subtab);
        if (index !== -1) {
            state.warningSubtabs.splice(index, 1);
        }
    },
    CLEAR_VALIDATION_STATE(state) {
        state.errorSubtabs = [];
        state.warningSubtabs = [];
        state.isVersionValid = true;
        state.validationMessages = [];
        state.validationAlert.message = '';
        state.validationAlert.description = '';
        state.prebidConfigTabsWithErrors = [];
        state.prebidConfigTabsWithWarnings = [];
        state.prebidBiddersWithErrors = {};
        state.prebidBiddersWithWarnings = {};
        state.prebidGroupsWithInvalidBidderParams = {};
        state.prebidGroupsWithEmptyBidderParams = {};
        state.incompatibleIdentityModules = [];
        state.errorSlots = [];
        state.warningSlots = [];
        state.slotNames = {};
    },
    ADD_PREBID_CONFIG_TAB_WITH_ERRORS(state, tab) {
        const index = state.prebidConfigTabsWithErrors.findIndex(st => st === tab);
        if (index === -1) {
            state.prebidConfigTabsWithErrors.push(tab);
        }
    },
    REMOVE_PREBID_CONFIG_TAB_WITH_ERRORS(state, tab) {
        const index = state.prebidConfigTabsWithErrors.findIndex(st => st === tab);
        if (index !== -1) {
            state.prebidConfigTabsWithErrors.splice(index, 1);
        }
    },
    ADD_PREBID_CONFIG_TAB_WITH_WARNINGS(state, tab) {
        const index = state.prebidConfigTabsWithWarnings.findIndex(
            st => st === tab
        );
        if (index === -1) {
            state.prebidConfigTabsWithWarnings.push(tab);
        }
    },
    REMOVE_PREBID_CONFIG_TAB_WITH_WARNINGS(state, tab) {
        const index = state.prebidConfigTabsWithWarnings.findIndex(
            st => st === tab
        );
        if (index !== -1) {
            state.prebidConfigTabsWithWarnings.splice(index, 1);
        }
    },
    ADD_PREBID_BIDDER_WITH_WARNINGS(state, bidderWithWarning) {
        const { groupUuid, bidderUuid } = bidderWithWarning;
        const allGroupsWithBidderWarnings = state.prebidBiddersWithWarnings;
        const groupWithBidderWarnigns = allGroupsWithBidderWarnings[groupUuid];
        if (groupWithBidderWarnigns) {
            const index = groupWithBidderWarnigns.findIndex(
                bidder => bidder === bidderUuid
            );
            if (index === -1) {
                groupWithBidderWarnigns.push(bidderUuid);
            }
        } else {
            allGroupsWithBidderWarnings[groupUuid] = [];
            allGroupsWithBidderWarnings[groupUuid].push(bidderUuid);
        }
        state.prebidBiddersWithWarnings = {};
        state.prebidBiddersWithWarnings = allGroupsWithBidderWarnings;
    },
    REMOVE_PREBID_BIDDER_WITH_WARNINGS(state, bidderWithWarning) {
        const { groupUuid, bidderUuid } = bidderWithWarning;
        const allGroupsWithBidderWarnings = state.prebidBiddersWithWarnings;
        const groupWithBidderWarnigns = allGroupsWithBidderWarnings[groupUuid];
        if (groupWithBidderWarnigns) {
            const index = groupWithBidderWarnigns.findIndex(
                bidder => bidder === bidderUuid
            );
            if (index !== -1) {
                groupWithBidderWarnigns.splice(index, 1);
            }
        }
        state.prebidBiddersWithWarnings = {};
        state.prebidBiddersWithWarnings = allGroupsWithBidderWarnings;
    },
    ADD_PREBID_BIDDER_WITH_ERRORS(state, bidderWithErrors) {
        const { groupUuid, bidderUuid } = bidderWithErrors;
        const allGroupsWithBidderErrors = state.prebidBiddersWithErrors;
        const groupWithBidderErrors = allGroupsWithBidderErrors[groupUuid];
        if (groupWithBidderErrors) {
            const index = groupWithBidderErrors.findIndex(
                bidder => bidder === bidderUuid
            );
            if (index === -1) {
                groupWithBidderErrors.push(bidderUuid);
            }
        } else {
            allGroupsWithBidderErrors[groupUuid] = [];
            allGroupsWithBidderErrors[groupUuid].push(bidderUuid);
        }
        state.prebidBiddersWithErrors = {};
        state.prebidBiddersWithErrors = allGroupsWithBidderErrors;
    },
    REMOVE_PREBID_BIDDER_WITH_ERRORS(state, bidderWithErrors) {
        const { groupUuid, bidderUuid } = bidderWithErrors;
        const allGroupsWithBidderErrors = state.prebidBiddersWithErrors;
        const groupWithBidderErrors = allGroupsWithBidderErrors[groupUuid];
        if (groupWithBidderErrors) {
            const index = groupWithBidderErrors.findIndex(
                bidder => bidder === bidderUuid
            );
            if (index !== -1) {
                groupWithBidderErrors.splice(index, 1);
            }
        }
        state.prebidBiddersWithErrors = {};
        state.prebidBiddersWithErrors = allGroupsWithBidderErrors;
    },
    REMOVE_PREBID_GROUP_WITH_INVALID_BIDDERS(state, groupUuid) {
        const allPrebidBiddersWithErrors = state.prebidBiddersWithErrors;
        if (allPrebidBiddersWithErrors[groupUuid]) {
            delete allPrebidBiddersWithErrors[groupUuid];
        }
        state.prebidBiddersWithErrors = {};
        state.prebidBiddersWithErrors = allPrebidBiddersWithErrors;

        const allPrebidGroupsWithInvalidBidders =
      state.prebidGroupsWithInvalidBidderParams;
        if (allPrebidGroupsWithInvalidBidders[groupUuid]) {
            delete allPrebidGroupsWithInvalidBidders[groupUuid];
        }
        state.prebidGroupsWithInvalidBidderParams = {};
        state.prebidGroupsWithInvalidBidderParams =
      allPrebidGroupsWithInvalidBidders;
    },
    SET_PREBID_GROUPS_WITH_INVALID_BIDDER_PARAMS(state, groupUuid) {
        const prebidGroupsWithInvalidBidders =
      state.prebidGroupsWithInvalidBidderParams;

        const groupWithBidderErrors = state.prebidBiddersWithErrors[groupUuid];

        const hasGroupBidderErrrors =
      groupWithBidderErrors && groupWithBidderErrors.length > 0;

        prebidGroupsWithInvalidBidders[groupUuid] = hasGroupBidderErrrors;
        state.prebidGroupsWithInvalidBidderParams = {};
        state.prebidGroupsWithInvalidBidderParams = prebidGroupsWithInvalidBidders;
    },
    SET_PREBID_GROUPS_WITH_EMPTY_BIDDER_PARAMS(state, groupUuid) {
        const prebidGroupsWithEmptyBidders =
      state.prebidGroupsWithEmptyBidderParams;

        const groupWithBidderWarnings = state.prebidBiddersWithWarnings[groupUuid];

        const hasGroupBidderWarnings =
      groupWithBidderWarnings && groupWithBidderWarnings.length > 0;

        prebidGroupsWithEmptyBidders[groupUuid] = hasGroupBidderWarnings;
        state.prebidGroupsWithEmptyBidderParams = {};
        state.prebidGroupsWithEmptyBidderParams = prebidGroupsWithEmptyBidders;
    },
    REMOVE_PREBID_GROUP_WITH_EMPTY_BIDDER_PARAMS(state, groupUuid) {
        const allPrebidBiddersWithWarnings = state.prebidBiddersWithWarnings;
        if (allPrebidBiddersWithWarnings[groupUuid]) {
            delete allPrebidBiddersWithWarnings[groupUuid];
        }
        state.prebidBiddersWithWarnings = {};
        state.prebidBiddersWithWarnings = allPrebidBiddersWithWarnings;

        const allPrebidGroupsWithEmptyBidders =
      state.prebidGroupsWithEmptyBidderParams;
        if (allPrebidGroupsWithEmptyBidders[groupUuid]) {
            delete allPrebidGroupsWithEmptyBidders[groupUuid];
        }
        state.prebidGroupsWithEmptyBidderParams = {};
        state.prebidGroupsWithEmptyBidderParams = allPrebidGroupsWithEmptyBidders;
    },
    ADD_INCOMPATIBLE_IDENTITY_MODULE(state, module) {
        if (!state.incompatibleIdentityModules.includes(module)) {
            state.incompatibleIdentityModules.push(module);
        }
    },
    REMOVE_INCOMPATIBLE_IDENTITY_MODULE(state, module) {
        const index = state.incompatibleIdentityModules.indexOf(module);
        if (index !== -1) {
            state.incompatibleIdentityModules.splice(index, 1);
        }
    },
    REMOVE_ALL_INCOMPATIBLE_IDENTITY_MODULES(state) {
        state.incompatibleIdentityModules = [];
    },
    ADD_ERROR_SLOT(state, slotUuid) {
        if (!state.errorSlots.includes(slotUuid)) {
            state.errorSlots.push(slotUuid);
        }
    },
    REMOVE_ERROR_SLOT(state, slotUuid) {
        const index = state.errorSlots.indexOf(slotUuid);
        if (index !== -1) {
            state.errorSlots.splice(index, 1);
        }
    },
    CLEAR_ERROR_SLOTS(state) {
        state.errorSlots = [];
    },
    ADD_WARNING_SLOT(state, slotUuid) {
        if (!state.warningSlots.includes(slotUuid)) {
            state.warningSlots.push(slotUuid);
        }
    },
    REMOVE_WARNING_SLOT(state, slotUuid) {
        const index = state.warningSlots.indexOf(slotUuid);
        if (index !== -1) {
            state.warningSlots.splice(index, 1);
        }
    },
    CLEAR_WARNING_SLOTS(state) {
        state.warningSlots = [];
    },
    SET_SLOT_NAMES(state, slotNames) {
        state.slotNames = slotNames;
    },
    SET_SLOT_NAME(state, {slotUuid, slotName}) {
        Vue.set(state.slotNames, slotUuid, slotName);
    },
    REMOVE_SLOT_NAME(state, slotUuid) {
        Vue.delete(state.slotNames, slotUuid);
    },
    CLEAR_ALL_SLOT_NAMES(state) {
        state.slotNames = {};
    }
};

const actions = {
    setIsVersionValid({ commit }, isVersionValid) {
        commit('SET_IS_VERSION_VALID', isVersionValid);
    },
    setValidationMessages({ commit }, messages) {
        commit('SET_VALIDATION_MESSAGES', messages);
    },
    setValidationAlertMessage({ commit }, messageOptions) {
        commit(
            'SET_VALIDATION_ALERT_MESSAGE',
            messageOptions || DEFAULT_VALIDATION_ALERT
        );
    },
    clearValidationAlertMessage({ commit }) {
        commit('CLEAR_VALIDATION_ALERT_MESSAGE');
    },
    setErrorSubtabs({ commit }, subtabs) {
        commit('SET_ERROR_SUBTABS', subtabs);
    },
    addErrorSubtab({ commit }, subtab) {
        commit('ADD_ERROR_SUBTAB', subtab);
    },
    removeErrorSubtab({ commit }, subtab) {
        commit('REMOVE_ERROR_SUBTAB', subtab);
    },
    addErrorVendor({ commit }, vendorKey) {
        commit('ADD_ERROR_VENDOR', vendorKey);
    },
    removeErrorVendor({ commit }, vendorKey) {
        commit('REMOVE_ERROR_VENDOR', vendorKey);
    },
    addDisVendor({ commit }, vendorKey) {
        commit('ADD_DIS_VENDOR', vendorKey);
    },
    removeDisVendor({ commit }, vendorKey) {
        commit('REMOVE_DIS_VENDOR', vendorKey);
    },
    setWarningSubtabs({ commit }, subtabs) {
        commit('SET_WARNING_SUBTABS', subtabs);
    },
    addWarningSubtab({ commit }, subtab) {
        commit('ADD_WARNING_SUBTAB', subtab);
    },
    removeWarningSubtab({ commit }, subtab) {
        commit('REMOVE_WARNING_SUBTAB', subtab);
    },
    clearValidationState({ commit }) {
        commit('CLEAR_VALIDATION_STATE');
    },
    addPrebidConfigTabWithErrors({ commit }, tab) {
        commit('ADD_PREBID_CONFIG_TAB_WITH_ERRORS', tab);
    },
    removePrebidConfigTabWithErrors({ commit }, tab) {
        commit('REMOVE_PREBID_CONFIG_TAB_WITH_ERRORS', tab);
    },
    addPrebidConfigTabWithWarnings({ commit }, tab) {
        commit('ADD_PREBID_CONFIG_TAB_WITH_WARNINGS', tab);
    },
    removePrebidConfigTabWithWarnings({ commit }, tab) {
        commit('REMOVE_PREBID_CONFIG_TAB_WITH_WARNINGS', tab);
    },
    addPrebidBidderWithWarnings({ commit }, bidderWithWarning) {
        commit('ADD_PREBID_BIDDER_WITH_WARNINGS', bidderWithWarning);
    },
    removePrebidBidderWithWarnings({ commit }, tab) {
        commit('REMOVE_PREBID_BIDDER_WITH_WARNINGS', tab);
    },
    addPrebidBidderWithErrors({ commit }, tab) {
        commit('ADD_PREBID_BIDDER_WITH_ERRORS', tab);
    },
    removePrebidBidderWithErrors({ commit }, tab) {
        commit('REMOVE_PREBID_BIDDER_WITH_ERRORS', tab);
    },
    setPrebidGroupsWithInvalidBidderParams({ commit }, groupUuid) {
        commit('SET_PREBID_GROUPS_WITH_INVALID_BIDDER_PARAMS', groupUuid);
    },
    setPrebidGroupsWithEmptyBidderParams({ commit }, groupUuid) {
        commit('SET_PREBID_GROUPS_WITH_EMPTY_BIDDER_PARAMS', groupUuid);
    },
    removePrebidGroupsWithInvalidBidders({ commit }, groupUuid) {
        commit('REMOVE_PREBID_GROUP_WITH_INVALID_BIDDERS', groupUuid);
    },
    removePrebidGroupsWithEmptyBidders({ commit }, groupUuid) {
        commit('REMOVE_PREBID_GROUP_WITH_EMPTY_BIDDER_PARAMS', groupUuid);
    },
    addIncompatibleIdentityModule({ commit }, module) {
        commit('ADD_INCOMPATIBLE_IDENTITY_MODULE', module);
    },
    removeIncompatibleIdentityModule({ commit }, module) {
        commit('REMOVE_INCOMPATIBLE_IDENTITY_MODULE', module);
    },
    removeAllIncompatibleIdentityModules({ commit }) {
        commit('REMOVE_ALL_INCOMPATIBLE_IDENTITY_MODULES', []);
    },
    addErrorSlot({ commit }, slotUuid) {
        commit('ADD_ERROR_SLOT', slotUuid);
    },
    removeErrorSlot({ commit }, slotUuid) {
        commit('REMOVE_ERROR_SLOT', slotUuid);
    },
    clearErrorSlots({ commit }) {
        commit('CLEAR_ERROR_SLOTS');
    },
    addWarningSlot({ commit }, slotUuid) {
        commit('ADD_WARNING_SLOT', slotUuid);
    },
    removeWarningSlot({ commit }, slotUuid) {
        commit('REMOVE_WARNING_SLOT', slotUuid);
    },
    clearWarningSlots({ commit }) {
        commit('CLEAR_WARNING_SLOTS');
    },
    setSlotNames({ commit }, slotNames) {
        commit('SET_SLOT_NAMES', slotNames);
    },
    setSlotName({ commit }, {slotUuid, slotName}) {
        commit('SET_SLOT_NAME', {slotUuid, slotName});
    },
    removeSlotName({ commit }, slotUuid) {
        commit('REMOVE_SLOT_NAME', slotUuid);
    },
};

const getters = {
    isVersionValid: ({ isVersionValid }) => isVersionValid,
    validationMessages: ({ validationMessages }) => validationMessages,
    validationAlert: ({ validationAlert }) => validationAlert,
    errorSubtabs: ({ errorSubtabs }) => errorSubtabs,
    errorVendors: ({ errorVendors }) => errorVendors,
    disVendors: ({ disVendors }) => disVendors,
    warningSubtabs: ({ warningSubtabs }) => warningSubtabs,
    prebidConfigTabsWithErrors: ({ prebidConfigTabsWithErrors }) =>
        prebidConfigTabsWithErrors,
    prebidConfigTabsWithWarnings: ({ prebidConfigTabsWithWarnings }) =>
        prebidConfigTabsWithWarnings,
    prebidBiddersWithErrors: ({ prebidBiddersWithErrors }) =>
        prebidBiddersWithErrors,
    prebidBiddersWithWarnings: ({ prebidBiddersWithWarnings }) =>
        prebidBiddersWithWarnings,
    prebidGroupsWithInvalidBidderParams: ({
        prebidGroupsWithInvalidBidderParams,
    }) => prebidGroupsWithInvalidBidderParams,
    prebidGroupsWithEmptyBidderParams: ({ prebidGroupsWithEmptyBidderParams }) =>
        prebidGroupsWithEmptyBidderParams,
    incompatibleIdentityModules: ({ incompatibleIdentityModules }) =>
        incompatibleIdentityModules,
    errorSlots: ({ errorSlots }) => errorSlots,
    warningSlots: ({ warningSlots }) => warningSlots,
    slotNames: ({ slotNames }) => slotNames,
};

export default {
    mutations,
    actions,
    getters,
    state,
};
