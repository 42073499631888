import vh from '@util/versionHelpers';
import ds from '@util/defaultSlots';
import helpers from '@util/helpers';
import uuidv4 from 'uuid/v4';
import { isNativeStylesInvalid } from '@util/validation/adConfiguration';

const mutations = {
    // native style mutations
    ADD_NEW_NATIVE_STYLE(state, style) {
        state.version.config.styles.styles = [
            ...state.version.config.styles.styles,
            style,
        ];
    },
    REMOVE_NATIVE_STYLE(state, uuid) {
        const updateStyles = state.version.config.styles.styles.filter(
            style => style.uuid !== uuid
        );
        state.version.config.styles.styles = updateStyles;
    },
    COPY_NATIVE_STYLE(state, style) {
        state.version.config.styles.styles = [
            ...state.version.config.styles.styles,
            style,
        ];
    },
    UPDATE_NATIVE_STYLE(state, styleOptions) {
        const { uuid, key, value } = styleOptions;
        const selectedStyle = state.version.config.styles.styles.find(
            style => style.uuid === uuid
        );
        selectedStyle[key] = value;
    },

    ADD_NEW_LAYOUT(state, layout) {
        state.version.config.layouts.layouts.push(layout);
    },
    REMOVE_LAYOUT(state, layoutUuid) {
        const updatedList = state.version.config.layouts.layouts.filter(
            l => l.uuid !== layoutUuid
        );
        state.version.config.layouts.layouts = updatedList;
    },
    COPY_LAYOUT(state, layout) {
        state.version.config.layouts.layouts.push(layout);
    },
    UPDATE_LAYOUT(state, layoutOptions) {
        const { layoutUuid, key, value } = layoutOptions;
        const selectedLayout = state.version.config.layouts.layouts.find(
            l => l.uuid === layoutUuid
        );
        selectedLayout[key] = value;
    },
    UPDATE_LAYOUT_POLL(state, pollOptions) {
        const { layoutUuid, key, value } = pollOptions;
        const selectedLayout = state.version.config.layouts.layouts.find(
            l => l.uuid === layoutUuid
        );
        selectedLayout.poll[key] = value;
    },
    ADD_NEW_LAYOUT_SLOT(state, slotOptions) {
        const { layoutUuid, slot } = slotOptions;
        const selectedLayout = state.version.config.layouts.layouts.find(
            l => l.uuid === layoutUuid
        );
        if (selectedLayout.key === 'universal') {
            const { htlbidGlobal } = state.version.config;
            const isImperativeApi = state.version.config.options.imperativeApi;
            slot.selector = isImperativeApi
                ? `#${slot.name ? slot.name : 'slot_name'}`
                : `.${htlbidGlobal}ad-${slot.name ? slot.name : 'slot_name'}`;
            slot.where = 'appendChildEach';
        }
        selectedLayout.slots.push(slot);
    },
    REMOVE_LAYOUT_SLOT(state, slotOptions) {
        const { layoutUuid, slotUuid } = slotOptions;
        const selectedLayout = state.version.config.layouts.layouts.find(
            l => l.uuid === layoutUuid
        );
        const updatedList = selectedLayout.slots.filter(s => s.uuid !== slotUuid);
        selectedLayout.slots = updatedList;
    },
    RESET_LAYOUTS(state) {
        state.version.config.layouts.layouts = [helpers.deepCopy(vh.defaultUniversalLayout)];
    },
    UPDATE_LAYOUT_SLOT(state, slotOptions) {
        const { layoutUuid, slotUuid, key, value } = slotOptions;
        const selectedLayout = state.version.config.layouts.layouts.find(
            l => l.uuid === layoutUuid
        );
        const selectedSlot = selectedLayout.slots.find(s => s.uuid === slotUuid);
        if (key === 'name' && selectedLayout.key === 'universal') {
            const { htlbidGlobal } = state.version.config;
            const isImperativeApi = state.version.config.options.imperativeApi;
            const newSelector = isImperativeApi
                ? `#${value ? value : 'slot_name'}`
                : `.${htlbidGlobal}ad-${value ? value : 'slot_name'}`;
            selectedSlot.selector = newSelector;
        }

        selectedSlot[key] = value;
    },
    REFRESH_UNIVERSAL_LAYOUT_SLOTS(state) {
        const selectedLayout = state.version.config.layouts.layouts.find(
            l => l.key === 'universal'
        );
        selectedLayout.slots.forEach(s => {
            const { htlbidGlobal } = state.version.config;
            const isImperativeApi = state.version.config.options.imperativeApi;
            s.selector = isImperativeApi
                ? `#${s.name ? s.name : 'slot_name'}`
                : `.${htlbidGlobal}ad-${s.name ? s.name : 'slot_name'}`;
        });
    },
    ADD_LAYOUT_EXTENSION(state, extensionOptions) {
        const { layoutUuid, extension, extensionName } = extensionOptions;
        const selectedLayout = state.version.config.layouts.layouts.find(
            l => l.uuid === layoutUuid
        );
        selectedLayout.extends = extensionName;
        selectedLayout.extendedUuid = extension;
    },
    ADD_NEW_SLOT_TARGETING(state, targetingOptions) {
        const { layoutUuid, slotUuid, targeting } = targetingOptions;
        const selectedLayout = state.version.config.layouts.layouts.find(
            l => l.uuid === layoutUuid
        );
        const selectedSlot = selectedLayout.slots.find(s => s.uuid === slotUuid);
        selectedSlot.targeting.push(targeting);
    },
    REMOVE_SLOT_TARGETING(state, targetingOptions) {
        const { layoutUuid, slotUuid, targetingUuid } = targetingOptions;
        const selectedLayout = state.version.config.layouts.layouts.find(
            l => l.uuid === layoutUuid
        );
        const selectedSlot = selectedLayout.slots.find(s => s.uuid === slotUuid);
        const updatedList = selectedSlot.targeting.filter(
            t => t.uuid !== targetingUuid
        );
        selectedSlot.targeting = updatedList;
    },
    UPDATE_SLOT_TARGETING(state, targetingOptions) {
        const { layoutUuid, slotUuid, targetingUuid, key, value } =
      targetingOptions;
        const selectedLayout = state.version.config.layouts.layouts.find(
            l => l.uuid === layoutUuid
        );
        const selectedSlot = selectedLayout.slots.find(s => s.uuid === slotUuid);
        const selectedTargeting = selectedSlot.targeting.find(
            t => t.uuid === targetingUuid
        );
        selectedTargeting[key] = value;
    },
    ADD_NEW_SLOT_SIZE_MAPPING(state, sizeMappingsOptions) {
        const { layoutUuid, slotUuid, sizeMapping } = sizeMappingsOptions;
        const selectedLayout = state.version.config.layouts.layouts.find(
            l => l.uuid === layoutUuid
        );
        const selectedSlot = selectedLayout.slots.find(s => s.uuid === slotUuid);
        selectedSlot.sizeMappings.push(sizeMapping);
    },
    REMOVE_SLOT_SIZE_MAPPING(state, sizeMappingsOptions) {
        const { layoutUuid, slotUuid, sizeMappingUuid } = sizeMappingsOptions;
        const selectedLayout = state.version.config.layouts.layouts.find(
            l => l.uuid === layoutUuid
        );
        const selectedSlot = selectedLayout.slots.find(s => s.uuid === slotUuid);
        const updatedList = selectedSlot.sizeMappings.filter(
            sm => sm.uuid !== sizeMappingUuid
        );
        selectedSlot.sizeMappings = updatedList;
    },
    UPDATE_SLOT_SIZE_MAPPING(state, sizeMappingsOptions) {
        const { layoutUuid, slotUuid, sizeMappingUuid, key, value } = sizeMappingsOptions;
        const selectedLayout = state.version.config.layouts.layouts.find(
            l => l.uuid === layoutUuid
        );
        const selectedSlot = selectedLayout.slots.find(s => s.uuid === slotUuid);
        const selectedSizeMapping = selectedSlot.sizeMappings.find(
            sm => sm.uuid === sizeMappingUuid
        );
        selectedSizeMapping[key] = value;
    },
    UPDATE_SLOT_SIZE_MAPPING_VIDEO_CONFIG(state, options) {
        const { layoutUuid, slotUuid, sizeMappingUuid, value } = options;

        const selectedLayout = state.version.config.layouts.layouts.find(
            l => l.uuid === layoutUuid
        );
        const selectedSlot = selectedLayout.slots.find(s => s.uuid === slotUuid);
        const selectedSizeMapping = selectedSlot.sizeMappings.find(
            sm => sm.uuid === sizeMappingUuid
        );

        selectedSizeMapping.sizes = [value];
    },
    UPDATE_SLOT_SIZE_MAPPING_OPTION(state, sizeMappingsOptions) {
        const { layoutUuid, slotUuid, sizeMappingUuid, key, value } = sizeMappingsOptions;
        const selectedLayout = state.version.config.layouts.layouts.find(
            l => l.uuid === layoutUuid
        );
        const selectedSlot = selectedLayout.slots.find(s => s.uuid === slotUuid);
        const selectedSizeMapping = selectedSlot.sizeMappings.find(
            sm => sm.uuid === sizeMappingUuid
        );
        selectedSizeMapping.options[key] = value;
    },
    SET_DEFAULT_PREBID_GROUPS(state, groups) {
        state.prebidGroups = groups;
    },
    UPDATE_SLOT_OPTIONS(state, slotOptions) {
        const { layoutUuid, slotUuid, key, value } = slotOptions;
        const selectedLayout = state.version.config.layouts.layouts.find(
            l => l.uuid === layoutUuid
        );
        const selectedSlot = selectedLayout.slots.find(s => s.uuid === slotUuid);

        if (key === 'optimeraSmartRefresh' && value === true) {
            selectedSlot.options.refresh = false;
        }
        if (key === 'refresh' && value === true) {
            selectedSlot.options.optimeraSmartRefresh = false;
        }
        if (key === 'interstitial' && value === true) {
            Object.keys(selectedSlot.options).forEach(optKey => {
                if (selectedSlot.options[optKey] === true && optKey !== 'eager') {
                    selectedSlot.options[optKey] = false;
                } else if (optKey === 'eager') {
                    selectedSlot.options[optKey] = true;
                }
            });
        }

        selectedSlot.options[key] = value;
    },
    UPDATE_SLOT_SIZE_MAPPING_PREBID_GROUP_NAME(state, newOptions) {
        const { groupUuid, value } = newOptions;
        const slots = state.version.config.layouts.layouts
            .map(layout => layout.slots)
            .flat();
        const sizeMappings = slots.map(slot => slot.sizeMappings).flat();

        sizeMappings.forEach(sm => {
            if (sm.groups && sm.groups.length > 0) {
                sm.groups.forEach((gr, index) => {
                    if (gr.uuid === groupUuid) {
                        sm.groups[index].name = value;
                    }
                });
            }
        });
    },
    DELETE_PREBID_GROUP_FROM_SLOT_SIZE_MAPPINGS(state, groupUuid) {
        const allSlotSizeMappings = state.version.config.layouts.layouts
            .map(l => l.slots)
            .flat()
            .map(s => s.sizeMappings)
            .flat();
        allSlotSizeMappings.forEach(
            sm => (sm.groups = sm.groups.filter(group => group.uuid !== groupUuid))
        );
    },
    ADD_NEW_DEVICE(state, device) {
        state.version.config.devices.push(device);
        state.version.config.devices = state.version.config.devices.sort((a,b) => {
            const aMin = a.minViewport.split('x')[0];
            const bMin = b.minViewport.split('x')[0];
            return aMin - bMin;
        });
    },
    UPDATE_DEVICE(state, deviceOptions) {
        const { deviceUuid, key, value } = deviceOptions;
        const selectedDevice = state.version.config.devices.find(
            d => d.uuid === deviceUuid
        );
        selectedDevice[key] = value;
    },
    SORT_DEVICES(state) {
        state.version.config.devices = state.version.config.devices.sort((a,b) => {
            const aMin = a.minViewport.split('x')[0];
            const bMin = b.minViewport.split('x')[0];
            return aMin - bMin;
        });
    },
    REMOVE_DEVICE(state, deviceUuid) {
        const updatedList = state.version.config.devices.filter(
            d => d.uuid !== deviceUuid
        );
        state.version.config.devices = updatedList;
    },
    UPDATE_RELATED_SLOT_VIEWPORTS(state, deviceOptions) {
        const { deviceUuid, viewport, device } = deviceOptions;

        const slots = state.version.config.layouts.layouts
            .map(layout => layout.slots)
            .flat();
        const sizeMappings = slots.map(slot => slot.sizeMappings).flat();

        sizeMappings.forEach(sm => {
            if (sm.viewport && sm.device && sm.device === deviceUuid) {
                sm.viewport = viewport;
                if (device !== undefined) sm.device = device;
            }
        });
    },
};

const actions = {
    // Native style actions

    addNewNativeStyle({ commit }) {
        const style = {
            uuid: uuidv4(),
            name: null,
            titleMaxLength: null,
            descriptionMaxLength: null,
            imgMaxWidth: null,
            imgMaxHeight: null,
            css: null,
            html: null,
        };
        commit('ADD_NEW_NATIVE_STYLE', style);
    },

    removeNativeStyle({ commit, state }, uuid) {
        commit('REMOVE_NATIVE_STYLE', uuid);
        const nativeStylesValid = !isNativeStylesInvalid(state.version.config);
        if (nativeStylesValid) {
            commit('REMOVE_WARNING_SUBTAB', 'native-styles');
            commit('REMOVE_ERROR_SUBTAB', 'native-styles');
            commit('CLEAR_VALIDATION_ALERT_MESSAGE');
        }
    },
    copyNativeStyle({ commit, state }, uuid) {
        const selectedStyle = helpers.deepCopy(
            state.version.config.styles.styles.find(s => s.uuid === uuid)
        );
        selectedStyle.uuid = uuidv4();
        const updatedName = 'copy_of_' + selectedStyle.name;
        selectedStyle.name = updatedName;
        commit('COPY_NATIVE_STYLE', selectedStyle);
    },

    updateNativeStyle({ commit }, styleOptions) {
        commit('UPDATE_NATIVE_STYLE', styleOptions);
    },

    addNewLayout({ commit, state }) {
        const layout = helpers.deepCopy(vh.defaultLayout);
        const layoutNumber = state.version.config.layouts.layouts.length + 1;
        layout.key = `template - ${layoutNumber}`;
        layout.uuid = uuidv4();
        layout.slots[0].uuid = uuidv4();
        layout.slots[0].sizeMappings[0].uuid = uuidv4();

        commit('ADD_NEW_LAYOUT', layout);
    },
    removeLayout({ commit }, layoutUuid) {
        commit('REMOVE_LAYOUT', layoutUuid);
    },
    copyLayout({ commit, state }, layoutUuid) {
        const selectedLayout = helpers.deepCopy(
            state.version.config.layouts.layouts.find(l => l.uuid === layoutUuid)
        );
        selectedLayout.uuid = uuidv4();
        selectedLayout.slots.forEach(s => (s.uuid = uuidv4()));
        selectedLayout.key = `copy_of_${selectedLayout.key}`;

        commit('COPY_LAYOUT', selectedLayout);
    },
    updateLayout({ commit, state }, layoutOptions) {
        commit('UPDATE_LAYOUT', layoutOptions);

        // depending on tabletAdSlot value, need to remove all size mappings that currently have tablet viewport
        if (layoutOptions.key === 'tabletAdSlot' && layoutOptions.value !== 'tablet') {
            const layoutUuid = layoutOptions.layoutUuid;

            const layout = state.version.config.layouts.layouts.find(l => l.uuid === layoutUuid);
            const slots = layout.slots;
            for (const slot of slots) {
                for (const sizeMapping of slot.sizeMappings) {
                    if (sizeMapping.viewport === '768x0') {
                        commit('REMOVE_SLOT_SIZE_MAPPING', {
                            layoutUuid,
                            slotUuid: slot.uuid,
                            sizeMappingUuid: sizeMapping.uuid,
                        });
                    }
                }

                // if remaining size mappings are empty, remove the slot
                if (slot.sizeMappings.length === 0) {
                    commit('REMOVE_LAYOUT_SLOT', {
                        layoutUuid,
                        slotUuid: slot.uuid,
                    });
                    commit('REMOVE_SLOT_NAME', slot.uuid);
                    commit('REMOVE_WARNING_SLOT', slot.uuid);
                    commit('REMOVE_ERROR_SLOT', slot.uuid);
                }
            }
        }
    },
    updateLayoutPoll({ commit }, pollOptions) {
        commit('UPDATE_LAYOUT_POLL', pollOptions);
    },
    addNewLayoutSlot({ commit }, layoutUuid) {
        const slot = helpers.deepCopy(vh.defaultSlot);
        slot.uuid = uuidv4();
        slot.componentUuid = uuidv4();
        commit('ADD_NEW_LAYOUT_SLOT', { layoutUuid, slot });
    },
    addDefaultLayoutSlots({ commit, state }, layoutUuid) {
        const slots = helpers.deepCopy(ds.defaultSlots);
        const selectedLayout = state.version.config.layouts.layouts.find(
            l => l.uuid === layoutUuid
        );
        const slotNames = selectedLayout.slots.map(slot => slot.name);
        const slotUnits = selectedLayout.slots.map(slot => slot.unit);
        slots.forEach(slot => {
            if (!slotNames.includes(slot.name) && !slotUnits.includes(slot.unit)) {
                commit('ADD_NEW_LAYOUT_SLOT', { layoutUuid, slot });
            }
        });
    },
    updateLayoutSlot({ commit }, slotOptions) {
        commit('UPDATE_LAYOUT_SLOT', slotOptions);
    },
    convertSlotToVideo({ commit }, options) {
        const { layoutUuid, slotUuid } = options;
        const newDevice = helpers.deepCopy(vh.defaultSizeMapping);

        commit('UPDATE_LAYOUT_SLOT', {
            layoutUuid,
            slotUuid,
            key: 'mediaType',
            value: 'video',
        });

        commit('UPDATE_LAYOUT_SLOT', {
            layoutUuid,
            slotUuid,
            key: 'sizeMappings',
            value: [newDevice],
        });
    },
    removeLayoutSlot({ commit }, slotOptions) {
        commit('REMOVE_LAYOUT_SLOT', slotOptions);
    },
    copyLayoutSlot({ commit, state }, slotOptions) {
        const { layoutUuid, slotUuid } = slotOptions;
        const selectedLayout = state.version.config.layouts.layouts.find(
            l => l.uuid === layoutUuid
        );
        const slot = helpers.deepCopy(
            selectedLayout.slots.find(s => s.uuid === slotUuid)
        );
        slot.uuid = uuidv4();
        slot.componentUuid = uuidv4();
        slot.name = `copy_of_${slot.name}`;

        commit('ADD_NEW_LAYOUT_SLOT', { layoutUuid, slot });
        commit('SET_SLOT_NAME', {
            slotUuid: slot.uuid,
            slotName: slot.name,
        });
    },
    addLayoutExtension({ commit, state }, extensionOptions) {
        extensionOptions.extensionName = null;
        if (extensionOptions.extension) {
            const extendedLayout = state.version.config.layouts.layouts.find(
                l => l.uuid === extensionOptions.extension
            );
            extensionOptions.extensionName = extendedLayout.key;
        }
        commit('ADD_LAYOUT_EXTENSION', extensionOptions);
    },
    addNewSlotTargeting({ commit }, slotOptions) {
        const { layoutUuid, slotUuid } = slotOptions;
        const targeting = helpers.deepCopy(vh.defaultSlotTargeting);
        targeting.uuid = uuidv4();
        commit('ADD_NEW_SLOT_TARGETING', { layoutUuid, slotUuid, targeting });
    },
    removeSlotTargeting({ commit }, slotOptions) {
        commit('REMOVE_SLOT_TARGETING', slotOptions);
    },
    updateSlotTargeting({ commit }, targetingOptions) {
        commit('UPDATE_SLOT_TARGETING', targetingOptions);
    },
    addNewSlotSizeMapping({ commit, state }, sizeMappingsOptions) {
        const { layoutUuid, slotUuid } = sizeMappingsOptions;
        const parentSlot = state.version.config.layouts.layouts.find(layout => layout.uuid === layoutUuid).slots.find(slot => slot.uuid === slotUuid);
        const sizeMapping = helpers.deepCopy(vh.defaultSizeMapping);
        const defaultSlotOptions = helpers.deepCopy(vh.defaultSlot.options);
        for (const key of Object.keys(sizeMapping.options)) {
            if (defaultSlotOptions[key] !== undefined) {
                sizeMapping.options[key] = parentSlot.options[key];
            }
        }
        sizeMapping.deviceSettingsOverride = false;
        sizeMapping.unit = parentSlot.unit;
        sizeMapping.uuid = uuidv4();
        commit('ADD_NEW_SLOT_SIZE_MAPPING', { layoutUuid, slotUuid, sizeMapping });
    },
    removeSlotSizeMapping({ commit }, sizeMappingsOptions) {
        commit('REMOVE_SLOT_SIZE_MAPPING', sizeMappingsOptions);
    },
    updateSlotSizeMapping({ commit }, sizeMappingsOptions) {
        commit('UPDATE_SLOT_SIZE_MAPPING', sizeMappingsOptions);
    },
    updateSlotSizeMappingOption({ commit }, sizeMappingsOptions) {
        commit('UPDATE_SLOT_SIZE_MAPPING_OPTION', sizeMappingsOptions);
    },
    updateSizeMappingVideoConfig({ commit }, options) {
        commit('UPDATE_SLOT_SIZE_MAPPING_VIDEO_CONFIG', options);
    },
    setDefaultPrebidGroups({ commit, state }) {
        const groupList = vh.mapPrebidGroups(
            state.version.config.prebid.groups || []
        );
        commit('SET_DEFAULT_PREBID_GROUPS', groupList);
    },
    updateSlotOptions({ commit }, slotOptions) {
        commit('UPDATE_SLOT_OPTIONS', slotOptions);
    },
    addNewDevice({ commit }, deviceType) {
        if (deviceType === 'default') {
            vh.defaultDevices.forEach(defaultDevice => {
                const newDefaultDevice = helpers.deepCopy(defaultDevice);
                newDefaultDevice.uuid = uuidv4();
                commit('ADD_NEW_DEVICE', newDefaultDevice);
            });
        } else {
            const newDevice = helpers.deepCopy(vh.device);
            newDevice.uuid = uuidv4();
            commit('ADD_NEW_DEVICE', newDevice);
        }
        commit('SYNC_AD_LOADING_OVERRIDE_DEVICES');
    },
    updateDevice({ commit }, deviceOptions) {
        commit('UPDATE_DEVICE', deviceOptions);
        commit('SYNC_AD_LOADING_OVERRIDE_DEVICES');
    },
    removeDevice({ commit }, deviceUuid) {
        commit('REMOVE_DEVICE', deviceUuid);
        commit('SYNC_AD_LOADING_OVERRIDE_DEVICES');
    },
    sortDevices({commit}) {
        commit('SORT_DEVICES');
    },
    updateRelatedSlotViewports({ commit }, deviceOptions) {
        commit('UPDATE_RELATED_SLOT_VIEWPORTS', deviceOptions);
    },
};

const getters = {
    // Getters Native Style

    styles: ({ version }) => {
        return version.config.styles.styles;
    },

    layouts: ({ version }) => {
        return vh.getMappedLayouts(
            version.config.layouts.layouts || [vh.defaultUniversalLayout]
        );
    },
    prebidGroups: ({ prebidGroups }) => prebidGroups || [],
    devices: ({ version }) => version.config.devices || [],
};

export default {
    mutations,
    actions,
    getters,
};
