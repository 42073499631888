import uuidv4 from 'uuid/v4';
import biddersUtil from '@util/bidders';
import helpers from '@util/helpers';
import moment from 'moment';
import versionHelpers from './versionHelpers';

const mapBidders = (biddersList, config, allBidders) => {
    const normalizedBidders = biddersUtil.getNormalizedBidders(config, allBidders);
    const updatedBidders = biddersList.map(bidder => {

        const getDefaultBidderData = () => {
            const normalizedBidder = normalizedBidders.find(b =>
                [b.name.toLowerCase(), b.key.toLowerCase()].includes(
                    bidder.bidder.toLowerCase()
                )
            );

            return normalizedBidder ? helpers.deepCopy(normalizedBidder) : null;
        };

        const foundBidder = getDefaultBidderData();
        if (!foundBidder) {
            return null;
        }

        // set uuid if there isn't one
        if (!bidder.uuid) {
            bidder.uuid = uuidv4();
        }

        // if bidder params are in old format (an object), remap the parameters to match
        // the format in @util/bidders => prebidBidders (as an Array)
        if (!Array.isArray(bidder.params)) {
            const defaultBidderData = foundBidder;
            // create a map for quicker key-based access
            const paramMap = new Map(defaultBidderData.params.map((param) => [param.key, param]));

            // loop through each param in old bidder format
            Object.entries(bidder.params).forEach(([key, value]) => {
                if (paramMap.has(key)) {
                    paramMap.get(key).value = value;
                }
            });

            bidder.params = Array.from(paramMap.values());
        }

        // refresh old bidder param size and rules
        let defaultBidderData = [];
        if (bidder.params.some(bp => !bp.rules)) {
            defaultBidderData = foundBidder;
        }
        bidder.params.map(param => {
            if (param.key === 'htlsize' && param.text === 'size') {
                param.text = 'Notes';
            }
            if (!param.rules) {
                const defaultBidderParam = defaultBidderData.params.find(
                    p => p.key === param.key
                );
                if (defaultBidderParam.rules)
                    param.rules = helpers.deepCopy(defaultBidderParam.rules);
            }
        });

        if (bidder.deprecated === undefined) {
            defaultBidderData = foundBidder;
            bidder.deprecated = defaultBidderData.deprecated;
        }

        // map special ix bidder - The IX bidder is a little tricky because they require an array of sizes.
        // as of prebid versin 4.43.0, the sizes parameter is optional.
        if (bidder.bidder === 'ix') {
            bidder.params.map(param => {
                if (param.key === 'size' && param.value) {
                    if (param.value.includes('native')) {
                        param.value = 'native';
                    } else {
                        if (!Array.isArray(param.value)) {
                            param.value = param.value.split('x');
                        }
                        param.value = `${param.value[0]}x${param.value[1]}`;
                    }
                }
            });
        }

        return bidder;
    }).filter(b => b);

    return updatedBidders;
};

const getDuplicateSlotUUIds = layouts => {
    const layoutSlotUUIDs = layouts.layouts.reduce((uuids, layout) => {
        const slotKeys = layout.slots.map(s => s.uuid);

        return [...uuids, ...slotKeys];
    }, []);

    let sorted = layoutSlotUUIDs.slice().sort();

    let duplicatedUUIDs = [];
    for (let i = 0; i < sorted.length - 1; i++) {
        if (sorted[i + 1] == sorted[i]) {
            if (!duplicatedUUIDs.includes(sorted[i])) {
                duplicatedUUIDs.push(sorted[i]);
            }
        }
    }

    return duplicatedUUIDs;
};

export const mapVersion = (versionRes, site, allBidders) => {
    let version = versionRes;
    version.note = '';
    version.is_prod = site.auto_deploy_prod;
    version.is_stage = site.auto_deploy_stage;
    // coerce int -> bool to force checkbox behavior
    version.is_abtest_parent = !!version.is_abtest_parent;

    for (const [key, value] of Object.entries(version.config.options)) {
        if (typeof value !== 'boolean') {
            version.config.options[key] = Boolean(value);
        }
    }

    if (!version.config.networks) {
        version.config.networks = [];
    }

    // Todo: remove this once there are no remaining networks with incorrect name.
    const fixConfigWithSingleBiddersFile = () => {
        version.config.networks.forEach(network => {
            switch (network.network) {
            case 'thirtythree_across':
                network.network = '33across';
                break;
            }
        });

        version.config.prebid.groups.forEach(group => {
            group.bidders.forEach(bidder => {
                switch (bidder.bidder) {
                case 'thirtythree_across':
                    bidder.bidder = '33across';
                    break;
                }
            });
        });
    };

    fixConfigWithSingleBiddersFile();

    const syncPageUrl = () => {
        if (version.config.prebid.hasCustomPageUrl === false) {
            version.config.prebid.pageUrl = null;
        }
    }

    syncPageUrl();

    const trimAndRemoveSpacesFromDeviceNames = () => {
        if (version.config.devices) {
            version.config.devices.forEach(device => {
                if (device.name.includes(' ')) {
                    device.name = device.name.trim().replace(' ', '_');
                }
            });
        }
    }

    trimAndRemoveSpacesFromDeviceNames();

    if (!version.config.options.hasPrivacyFlags) {
        version.config.options.hasPrivacyFlags = false;
    }
    if (!version.config.privacyFlags) {
        version.config.privacyFlags = {
            gam: {
                childDirectedTreatment: false,
                limitedAds: false,
                restrictDataProcessing: false,
                underAgeOfConsent: false,
            },
            prebid: {
                coppa: false,
                deviceAccess: false,
            }
        }
    }
    if (!version.config.options.hasCustomJs) {
        version.config.options.hasCustomJs = false;
    }
    if (version.config.prebid && !version.config.prebid.fixBidderSequence) {
        version.config.prebid.fixBidderSequence = false;
    }
    if (Array.isArray(version.config.prebidUserSync)) {
        version.config.prebidUserSync = version.config.prebidUserSync[0];
    }
    if (version.config.options.hasPrebid) {
        version.config.prebid.groups.forEach((group) => {
            if (group.bidders.length < 1) {
                const appNexus = helpers.deepCopy(biddersUtil.defaultAppnexusBidder);
                const bidder = {
                    active: true,
                    bidder: appNexus.key,
                    params: [...appNexus.params],
                    deprecated: appNexus.deprecated,
                    uuid: uuidv4(),
                };

                group.bidders.push(bidder);
            }
        });
    }
    if (!version.config.options.hasPageviewPixel) {
        version.config.options.hasPageviewPixel = true;
    }
    if (version.config.abTesting.testVersions[0]?.allocation === null) {
        version.config.abTesting.testVersions[0].allocation = 0;
    }

    //authorized domains
    if (!version.config.options.hasAuthorizedDomains) {
        version.config.options.hasAuthorizedDomains = false;
    }
    if (!version.config.authorizedDomains) {
        version.config.authorizedDomains = [];
    }

    // disable removed modules
    if (version.config.options.hasHaloId) {
        version.config.options.hasHaloId = false;
    }

    if (version.config.authorizedDomains.length === 0) {
        version.config.authorizedDomains.push(site.domain);
    } else if (!version.config.authorizedDomains.includes(site.domain)) {
        version.config.authorizedDomains.unshift(site.domain);
    }

    // PREBID CONFIGURATION
    if (version.config.prebid) {
        if (!version.config.prebid.pbjsGlobal) {
            version.config.prebid.pbjsGlobal = '';
        }
        if (!version.config.prebid.hasCustomPageUrl) {
            version.config.prebid.hasCustomPageUrl = false;
        }
        if (!version.config.prebid.pageUrl) {
            version.config.prebid.pageUrl = null;
        }
    }

    if (version.config.prebid.enableSendAllBids === undefined) {
        version.config.prebid.enableSendAllBids = true;
    }
    if (version.config.prebid.enableTIDs === undefined) {
        // default to `true` cause this config is new in prebid 8.
        // in prebid versions < 8 enableTIDs was considered as true.
        version.config.prebid.enableTIDs = true;
    }
    if (version.config.prebid.hasAdvertiserBlockList === undefined) {
        version.config.prebid.hasAdvertiserBlockList = false;
    }
    if (version.config.prebid.hasSecondaryBidders === undefined) {
        version.config.prebid.hasSecondaryBidders = false;
        version.config.prebid.secondaryBidders = [];
    }

    // PREBID ID GROUPS
    if (version.config.prebid) {
        if (version.config.prebid.groups) {
            version.config.prebid.groups = version.config.prebid.groups.map(pg => {
                // set uuid for bidder group
                if (!pg.uuid) pg.uuid = uuidv4();

                // bidder params need to be changed from OBJECT format to ARRAY of objects format
                // with that change we need to handle older bidder params which is done in the mapBidders helper method
                pg.bidders = mapBidders(pg.bidders, version.config, allBidders);

                return pg;
            });
        }
    }

    // PREBID USER SYNC
    if (version.config.prebidUserSync) {
        if (!version.config.prebidUserSync.syncType) {
            version.config.prebidUserSync.syncType = 'default';
        }
        if (!version.config.prebidUserSync.syncsPerBidder) {
            version.config.prebidUserSync.syncsPerBidder = '5';
        }
        if (!version.config.prebidUserSync.syncDelay) {
            version.config.prebidUserSync.syncDelay = 3000;
        }
        if (!version.config.prebidUserSync.auctionDelay) {
            version.config.prebidUserSync.auctionDelay = 0;
        }
        if (!version.config.prebidUserSync.syncsAllowed) {
            version.config.prebidUserSync.syncsAllowed = '';
        }
        if (!version.config.prebidUserSync.aliasSyncEnabled) {
            version.config.prebidUserSync.aliasSyncEnabled = false;
        }
    }

    if (version.config.amazon) {
        if (!version.config.amazon.pubId) {
            version.config.amazon.pubId = '';
        }
        if (!version.config.amazon.floor) {
            version.config.amazon.floor = '';
        }
        if (version.config.amazon.deals == null) {
            version.config.amazon.deals = false;
        }
        if (version.config.amazon.v2api == null) {
            version.config.amazon.v2api = true;
        }
        if (!('autoInit' in version.config.amazon)) {
            version.config.amazon.autoInit = true;
        }
    }

    // PREBID CURRENCY
    if (version.config.prebidCurrency) {
        if (!version.config.prebidCurrency.adServerCurrency) {
            version.config.prebidCurrency.adServerCurrency = '';
        }
        if (!version.config.prebidCurrency.granularityMultiplier) {
            version.config.prebidCurrency.granularityMultiplier = '';
        }
    }

    // PREBID SERVER
    if (version.config.prebidServer) {
        // As of zd20874, we need to keep accountId for prebidServer configuration,
        // but it needs to be updated in existing revisions.
        // accountId just needs to be different when using a non htl pbjs.
        if (!version.config.prebidServer.accountId) {
            version.config.prebidServer.accountId = String(site.account_id);
        }

        if (version.config.prebidServer.enabled === undefined) {
            version.config.prebidServer.enabled = true;
        }
        if (!version.config.prebidServer.bidders) {
            version.config.prebidServer.bidders = [];
        }

        if (!version.config.prebidServer.defaultVendor) {
            version.config.prebidServer.defaultVendor = {
                enabled: false,
                value: null,
            };
        }

        if (!version.config.prebidServer.timeout) {
            version.config.prebidServer.timeout = 1000;
        }
        if (!version.config.prebidServer.endpoint) {
            version.config.prebidServer.endpoint = '';
        }
        if (!version.config.prebidServer.syncEndpoint) {
            version.config.prebidServer.syncEndpoint = '';
        }
    }

    // PREBID BID ADJUSTMENTS
    if (!version.config.prebidBidAdjustments) {
        version.config.prebidBidAdjustments = [];
    } else {
        version.config.prebidBidAdjustments.forEach(
            adjustment => (adjustment.adjustment = adjustment.adjustment.toString())
        );
    }

    // PREBID FIRST LOOK
    if (!version.config.prebidFirstLook) {
        version.config.prebidFirstLook = {
            globalFloor: '0.00',
            bidders: [],
        };
    }

    // Prebid floors
    // need this to make it reactive
    version.config.prebidFloorsString = null;

    if (!version.config.prebidFloors) {
        version.config.prebidFloors = {};
    }
    if (!version.config.prebidFloors.custom) {
        version.config.prebidFloors.custom = {};
    }
    if(!version.config.prebidFloors.skipRate) {
        version.config.prebidFloors.skipRate = {
            enabled: false,
            keyName: 'skip',
            value: 0,
        }
    }
    if(!version.config.prebidFloors.floorReporting) {
        version.config.prebidFloors.floorReporting = {
            enabled: false,
            keyName: 'hasFloors',
        }
    }
    if (!version.config.prebidFloors.rules) {
        version.config.prebidFloors.rules = [];
    }
    if (!version.config.prebidFloors.fields) {
        version.config.prebidFloors.fields = ['device'];
    }

    // for updating old layouts with uuid and extendedUuid
    const hasLayouts = version.config.layouts && version.config.layouts.layouts;
    if (!hasLayouts) {
        const universalLayouts = [versionHelpers.defaultUniversalLayout];
        version.config.layouts = {
            layouts: universalLayouts,
        };
    }
    if (version.config.layouts.layouts.length > 0) {
        version.config.layouts.layouts.map(layout => {
            // add uuid if it doesn't exist
            if (!layout.uuid) {
                layout.uuid = uuidv4();
            } else {
                const otherLayoutKeys = version.config.layouts.layouts
                    .filter(l => l !== layout)
                    .map(l => l.uuid);
                if (otherLayoutKeys.includes(layout.uuid)) {
                    layout.uuid = uuidv4();
                }
            }

            if (!layout.tabletAdSlot) {
                layout.tabletAdSlot = 'desktop';
            }

            if (!('sizeMappingOverride' in layout) || !version.config.options.imperativeApi) {
                layout.sizeMappingOverride = true
            }

            if (!('refreshProtection' in layout)) {
                layout.refreshProtection = true
            }

            if (!('refreshCounter' in layout)) {
                layout.refreshCounter = false
            }
            if (!('refreshCounterValue' in layout)) {
                layout.refreshCounterValue = 'htl_refresh'
            }

            // add extendedUuid property to layouts that don't have it but have and extension
            if (
                !layout.extendedUuid &&
                layout.extends &&
                layout.key !== 'universal'
            ) {
                const parentLayout = version.config.layouts.layouts.find(
                    lay => lay.key === layout.extends
                );
                if (!parentLayout.uuid) {
                    parentLayout.uuid = uuidv4();
                }
                layout.extendedUuid = parentLayout.uuid;
            }

            if (typeof layout.poll === 'undefined' || !layout.poll) {
                layout.poll = {
                    interval: 2000,
                    selector: '',
                    type: null,
                    callback: '',
                };
            }

            if (layout.poll.type && !layout.poll.interval) {
                layout.poll.interval = 2000;
            }
            if (layout.poll.type && !layout.poll.callback) {
                layout.poll.callback = '';
            }
            if (layout.poll.type && !layout.poll.selector) {
                layout.poll.selector = '';
            }

            layout.slots.map(slot => {
                // add uuid if it doesn't exist
                if (!slot.uuid) {
                    slot.uuid = uuidv4();
                } else {
                    const otherSlotsKeys = getDuplicateSlotUUIds(version.config.layouts);

                    if (otherSlotsKeys.includes(slot.uuid)) {
                        slot.uuid = uuidv4();
                    }
                }

                if (!slot.componentUuid) {
                    slot.componentUuid = uuidv4();
                }

                //slot options default values
                if (!slot.options.lazyPixels) {
                    slot.options.lazyPixels = '350';
                }
                if (!slot.options.stickyMinutes) {
                    slot.options.stickyMinutes = '0';
                }
                if (!slot.options.stickyFreqMinutes) {
                    slot.options.stickyFreqMinutes = '60';
                }
                if (!slot.options.refreshType) {
                    slot.options.refreshType = 'viewable';
                }
                if (!slot.options.refreshSecs) {
                    slot.options.refreshSecs = '60';
                }
                if (!slot.options.maxRefreshes) {
                    slot.options.maxRefreshes = '500';
                }
                if (!slot.options.stickyMinutes) {
                    slot.options.stickyMinutes = '0';
                }
                if (!slot.options.companion) {
                    slot.options.companion = false;
                }
                if (!slot.options.optimeraSmartRefresh) {
                    slot.options.optimeraSmartRefresh = false;
                }
                if (!slot.options.optimeraMinTimeInView) {
                    slot.options.optimeraMinTimeInView = 1.1;
                }
                if (!slot.options.optimeraMinTimeOnPage) {
                    slot.options.optimeraMinTimeOnPage = 30;
                }
                if (!('interstitial' in slot.options)) {
                    slot.options.interstitial = false;
                }

                slot.targeting.map(targeting => {
                    // add uuid if it doesn't exist
                    if (!targeting.uuid) {
                        targeting.uuid = uuidv4();
                    }
                });

                slot.sizeMappings.map(sizeMapping => {
                    // add uuid if it doesn't exist
                    if (!sizeMapping.uuid) {
                        sizeMapping.uuid = uuidv4();
                    }
                    if (!sizeMapping.groups) {
                        sizeMapping.groups = [];
                    } else {
                        // delete group if it does not exist in prebid groups
                        sizeMapping.groups = sizeMapping.groups.filter(group =>
                            version.config.prebid.groups.map(g => g.name).includes(group)
                        );
                        sizeMapping.groups = sizeMapping.groups.map(group => {
                            const prebidGroup = version.config.prebid.groups.find(
                                g => g.name === group
                            );
                            return Object.assign({
                                name: prebidGroup.name,
                                uuid: prebidGroup.uuid,
                            });
                        });
                    }
                    if (!sizeMapping.device) {
                        sizeMapping.device = '';
                    }
                });

                if (
                    version.config.options.imperativeApi &&
                    layout.key === 'universal'
                ) {
                    slot.selector = `#${slot.name ? slot.name : 'slot_name'}`;
                }
            });
        });
    }

    if (!version.config.devices) {
        version.config.devices = versionHelpers.loadVersionDevices(version.config);
        versionHelpers.mapVersionDevicesToViewports(version.config);
    }

    if (!version.config.adLoadingOverride) {
        version.config.adLoadingOverride = {
            option: 'delayAdLoad',
            devices: [],
        };
    }

    version.config.adLoadingOverride.devices = version.config.devices.map(
        device => {
            const existingDevice = version.config.adLoadingOverride.devices.find(
                d => d.deviceUuid === device.uuid
            );
            const deviceDelayTime = existingDevice ? existingDevice.delayTime : '';

            return {
                deviceUuid: device.uuid,
                device: device.name,
                delayTime: deviceDelayTime,
            };
        }
    );

    if (
        version.config.adLoadingOverride &&
    version.config.adLoadingOverride.devices &&
    version.config.adLoadingOverride.devices.length > 1
    ) {
        version.config.adLoadingOverride.devices =
      version.config.adLoadingOverride.devices.filter(
          d => d.device !== versionHelpers.defaultViewportName
      );
    }

    // GDPR STRATEGY
    if (version.config.consentManager) {
        if (!version.config.consentManager.publisherName) {
            version.config.consentManager.publisherName = '';
        }
        if (!version.config.consentManager.publisherPurposeIds) {
            version.config.consentManager.publisherPurposeIds = [];
        }
    }

    // CONSENT MANAGEMENT
    if (version.config.consentManagerVersion2) {
        if (!version.config.consentManagerVersion2.settings) {
            version.config.consentManagerVersion2.settings = {};
        }
        if (!version.config.consentManagerVersion2.settings.publisherId) {
            version.config.consentManagerVersion2.settings.publisherId = '';
        }
        if (!version.config.consentManagerVersion2.settings.domainId) {
            version.config.consentManagerVersion2.settings.domainId = '';
        }

        // default UI settings for CMP config
        const settings = {
            uspSignal: 'ca-only',
            tcfSignal: 'eea-only',
            gppSignal: 'never',
            mspaSignal: 'never',
        }

        // if config has old settings, import them to the new data format
        if (version.config.consentManagerVersion2.settings.signalType) {
            const signalType = version.config.consentManagerVersion2.settings;

            if (signalType === 'usp') {
                settings.tcfSignal = 'never';
            }
            else if (signalType === 'tcf') {
                settings.uspSignal = 'never';
            }
            else if (signalType === 'none') {
                settings.tcfSignal = 'never';
                settings.uspSignal = 'never';
            }

            // assign the settings
            version.config.consentManagerVersion2.settings = settings;

            // important to delete old key, so this import logic only happens once per site
            delete version.config.consentManagerVersion2.settings.signalType;
        }

        // if no settings, set defaults (ex: a new site)
        if (!version.config.consentManagerVersion2.settings.uspSignal) {
            version.config.consentManagerVersion2.settings = settings;
        }

        // otherwise, settings already existed, so we leave them unchanged

        if (!version.config.consentManagerVersion2.settings.uspSignal) {
            version.config.consentManagerVersion2.settings.uspSignal = 'ca-only';
        }
        if (!version.config.consentManagerVersion2.settings.tcfSignal) {
            version.config.consentManagerVersion2.settings.tcfSignal = 'eea-only';
        }
        if (!version.config.consentManagerVersion2.settings.gppSignal) {
            version.config.consentManagerVersion2.settings.gppSignal = 'never';
        }
        if (!version.config.consentManagerVersion2.settings.mspaSignal) {
            version.config.consentManagerVersion2.settings.mspaSignal = 'never';
        }

        if (!version.config.consentManagerVersion2.options) {
            version.config.consentManagerVersion2.options = {};
        }
        if (!version.config.consentManagerVersion2.options.name) {
            version.config.consentManagerVersion2.options.name = '';
        }
        if (!version.config.consentManagerVersion2.options.popupCode) {
            version.config.consentManagerVersion2.options.popupCode = '';
        }
        if (!version.config.consentManagerVersion2.options.buttonCode) {
            version.config.consentManagerVersion2.options.buttonCode =
        'console.log("Button Javascript Code placeholder");';
        }
    }

    // ACCOUNT INFORMATION
    if (version.config.networks) {
        version.config.networks.forEach(network => {
            if (!('schain' in network)) {
                network.schain = versionHelpers.isSupplyChainEligible(version.config, network);
            } else if (!network.schain) {
                network.schain = false;
            }
        });
        version.config.networks = versionHelpers.loadVersionNetworks(
            version.config
        );
        version.config.networks = versionHelpers.syncVersionNetworks(
            version.config
        );
    }

    //GLOBAL CONFIGURATION
    if (!version.config.dfpNetworkId) {
        version.config.dfpNetworkId = '';
    }
    if (version.config.gamCollapseEmptyDivs === undefined) {
        version.config.gamCollapseEmptyDivs = true;
    }
    if (!version.config.gamUserEmail) {
        version.config.gamUserEmail = '';
    }
    if (!version.config.htlbidGlobal) {
        version.config.htlbidGlobal = '';
    }
    if (!version.config.devClientBranch) {
        version.config.devClientBranch = '';
    }

    //ACCOUNT INFORMATION
    if (!version.config.sellerId) {
        version.config.sellerId = site.seller_id;
    }
    if (!version.config.sellerDomain) {
        version.config.sellerDomain = '';
    }
    if (!version.config.publisherDomain) {
        version.config.publisherDomain = site.domain;
    }

    //IDENTITY

    // AB TESTING
    const hasABTesting =
    version.config.abTesting && version.config.abTesting.testVersions;
    if (hasABTesting) {
        version.config.abTesting.testVersions.forEach(testVersion => {
            if (!testVersion.versionId) {
                testVersion.versionId = '';
            }
        });
    }

    // handle cwui and aditudeOverride
    if (typeof version.config.aditudeOverride === 'object') {
        if (version.config.aditudeOverride.enabled) {
            version.config.publisherKey = version.config.aditudeOverride.publisherKey || '';
            version.config.wrapperName = version.config.aditudeOverride.wrapperName || '';
        }
        version.config.aditudeOverride = version.config.aditudeOverride.enabled;
    }

    if (typeof version.config.cwUI === 'object') {
        if (version.config.cwUI.enabled) {
            version.config.publisherKey = version.config.cwUI.publisherKey || '';
            version.config.wrapperName = version.config.cwUI.wrapperName || '';
        }
        version.config.cwUI = version.config.cwUI.enabled;
    }

    return version;
};

export const mapTestVersions = testVersionsRes => {
    return testVersionsRes.map(v => ({
        value: v.version_id,
        text: `Rev. ${v.version} (HTL BID ID ${v.version_id}): ${v.note}; ${moment(
            v.created_at
        ).format('MM-DD-YY')}`,
    }));
};

export const getDeployTargets = version => {
    if (version.is_stage && version.is_prod) {
        return 'none';
    }
    if (version.is_stage) {
        return 'prod';
    }
    if (version.is_prod) {
        return 'stage';
    }
    return 'either';
};

export const canDeploy = version => {
    return version.status === 'SUCCESS' && getDeployTargets(version) !== 'none';
};

export const formatForSave = (config) => {
    for (const { bidders } of config.prebid.groups) {
        for (const bidder of bidders) {
            let { params } = bidder;
            if (!params) {
                return;
            }

            // if bidder params are an array (new format), remap the array parameters to match backend format (object-> key:value)
            if (Array.isArray(params)) {
                if (bidder.bidder === 'ix') {
                    formatIXBidderParams(params);
                }

                params = bidder.params = Object.fromEntries(params.map(({ key, value }) => [key, value]));
            }

            for (const [key, value] of Object.entries(params)) {
                if (typeof value === 'string') {
                    params[key] = value.trim();
                }
            }
        }
    }

    //remove empty prebid adjustments and adjustments for non-existing groups
    const bidders = new Set(
        config.prebid.groups
            .flatMap(({ bidders }) => bidders)
            .filter(({ active, bidder }) => active && bidder !== 'optimera')
            .map(({ bidder }) => bidder)
    );
    config.prebidBidAdjustments = config.prebidBidAdjustments.filter(
        adjustment =>
            adjustment.bidder &&
            bidders.has(adjustment.bidder) &&
            adjustment.adjustment &&
            adjustment.adjustment.trim()
    );
    config.prebidBidAdjustments.forEach((adjustment) => {
        adjustment.adjustment = parseInt(adjustment.adjustment.trim(), 10);
    });
    config.prebidFirstLook.bidders = config.prebidFirstLook.bidders.filter((b) => bidders.has(b));

    for (const layout of config.layouts.layouts || []) {
        for (const slot of layout.slots) {
            const { options } = slot;
            for (const key of ['lazyPixels', 'refreshSecs', 'maxRefreshes', 'optimeraMinTimeInView', 'optimeraMinTimeOnPage']) {
                options[key] = Number(options[key]);
            }

            if (slot.sizeMappings) {
                for (const sm of slot.sizeMappings) {
                    sm.groups = sm.groups ? sm.groups.map(({ name }) => name) : [];
                }
            }
        }
    }

    // undefined will be ignored by JSON.stringify
    config.prebidFloorsString = undefined;

    return config;
};

export const formatForBuild = (site, version) => {
    const copiedVersion = helpers.deepCopy(version);

    // SET DEFAULT VALUES IN CONFIG
    const { options } = copiedVersion.config;

    if (!options.hasAmazon) {
        copiedVersion.config.amazon.pubId = null;
    }
    if (!options.hasPrebidServer) {
        copiedVersion.config.hasPrebidServer = {};
    }
    if (!options.hasPrebidCurrency) {
        copiedVersion.config.hasPrebidCurrency = {};
    }
    if (!options.hasPrebidFloors) {
        copiedVersion.config.hasPrebidFloors = false;
    }
    if (!options.hasPrebidVideo) {
        copiedVersion.config.hasPrebidVideo = false;
    }
    if (!options.hasPassbacks) {
        copiedVersion.config.passbacks = [];
    }
    if (!options.hasConsentManager) {
        copiedVersion.config.consentManager.type = null;
    }
    if (!options.hasConsentManagerVersion2) {
        copiedVersion.config.consentManagerVersion2.type = null;
        copiedVersion.config.consentManagerVersion2.options = {};
        copiedVersion.config.consentManagerVersion2.settings = {};
    }
    if (!options.hasLayouts) {
        copiedVersion.config.layouts = {
            layouts: [
                {...copiedVersion.config.layouts.layouts[0], ...{refreshProtection: true, slots: []}}
            ]
        };
    }
    if (!options.hasDefaultLazyPixels) {
        copiedVersion.config.defaultLazyPixels = null;
    }
    if (!options.hasCss) {
        copiedVersion.config.css = null;
    }
    if (!options.hasPrebid) {
        copiedVersion.config.prebid = {
            version: null,
            versionType: 'default',
            useBidCache: false,
            timeout: 2000,
            pbjsGlobal: null,
            granularity: 'default',
            customGranularity: null,
            consentManagement: null,
            prebidServer: {},
            groups: [],
            userSync: {
                syncEnabled: true,
                syncsPerBidder: 5,
                syncDelay: 3000,
                auctionDelay: 0,
                enableOverride: false,
                filterSettings: {},
            },
        };
    }

    // Unset "version" unless this is an "exact version" of prebid
    if (copiedVersion.config.prebid.versionType !== 'exact') {
        copiedVersion.config.prebid.version = null;
    }

    // SORT PREBID GROUPS
    copiedVersion.config.prebid.groups.sort(helpers.dynamicSort('name'));

    // LAYOUT CLEANUP
    if (copiedVersion.config.layouts.layouts) {
        copiedVersion.config.layouts.layouts.forEach(l => {
            if (l.key === 'universal') {
                l.extends = null;
                l.rules = [];
            }
            l.slots.sort(helpers.dynamicSort('name'));
            l.slots.forEach(s => {
                if (!s.uuid) {
                    s.uuid = uuidv4();
                }

                if (s.options.eager) {
                    s.options.lazyPixels = null;
                }
                if (!s.options.sticky) {
                    s.options.stickyMinutes = null;
                    s.options.stickyFreqCap = null;
                    s.options.stickyCanClose = null;
                    s.options.stickyFreqMinutes = null;
                }
            });
        });
    }

    copiedVersion.config = formatForSave(copiedVersion.config);

    if (!site.creates_kv) {
        copiedVersion.is_abtest_allowed = 0;
    }

    return copiedVersion;
};

const formatIXBidderParams = (params) => {
    for (const param of params) {
        const { key, value } = param;
        if (key === 'size' && value) {
            if (value === 'native') {
                param.value = ['native'];
            } else if (value.indexOf('x') > 0) {
                param.value = value.split('x').map(e => parseInt(e, 10));
            } else {
                param.value = [];
            }
        }

    }
};
